

import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { User, UserManagementApiService } from '@snps-webapp/angular-api-client/user-management';
import { MultiPurposeFindQueryParams, PaginatedDataSource } from '@synopsys-inc/angular-ms-common-lib';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent {

  constructor(private userManagementApiService: UserManagementApiService) { }

  public columns = ["userName", "customerId", "email", "firstName", "lastName", "[roles]", "siteId"]

  // Data source uses pagination API of ms-common-lib CRUD BASE controller used in user management 
  public dataSource: PaginatedDataSource<User> = new PaginatedDataSource<User>((queryParams: MultiPurposeFindQueryParams) => {
    return this.userManagementApiService.userControllerPostQueryItems({body:queryParams})
  }, { active: 'userName', direction: 'desc' });

}