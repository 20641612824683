/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Entitlement } from '../models/entitlement';
import { EntitlementDto } from '../models/entitlement-dto';
import { LoginRequest } from '../models/login-request';
import { LoginResponse } from '../models/login-response';

@Injectable({
  providedIn: 'root',
})
export class EntitlementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation entitlementControllerFindUserEntitlement
   */
  static readonly EntitlementControllerFindUserEntitlementPath = '/entitlement/{userId}';

  /**
   * Find user entitlement.
   *
   * Fetch the entitlement for a specific user by their user ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitlementControllerFindUserEntitlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitlementControllerFindUserEntitlement$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<Entitlement>> {

    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementControllerFindUserEntitlementPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Entitlement>;
      })
    );
  }

  /**
   * Find user entitlement.
   *
   * Fetch the entitlement for a specific user by their user ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `entitlementControllerFindUserEntitlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitlementControllerFindUserEntitlement(params: {
    userId: string;
  }): Observable<Entitlement> {

    return this.entitlementControllerFindUserEntitlement$Response(params).pipe(
      map((r: StrictHttpResponse<Entitlement>) => r.body as Entitlement)
    );
  }

  /**
   * Path part for operation entitlementControllerCreateEntitlement
   */
  static readonly EntitlementControllerCreateEntitlementPath = '/entitlement/mock';

  /**
   * Create entitlement.
   *
   * Store a new entitlement service response for a user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitlementControllerCreateEntitlement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitlementControllerCreateEntitlement$Response(params: {
    body: EntitlementDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementControllerCreateEntitlementPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create entitlement.
   *
   * Store a new entitlement service response for a user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `entitlementControllerCreateEntitlement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitlementControllerCreateEntitlement(params: {
    body: EntitlementDto
  }): Observable<void> {

    return this.entitlementControllerCreateEntitlement$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation entitlementControllerCreateLogin
   */
  static readonly EntitlementControllerCreateLoginPath = '/entitlement';

  /**
   * Create login record from IjwtUserInfo.
   *
   * Record the users login details from the IjwtUserInfo object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitlementControllerCreateLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitlementControllerCreateLogin$Response(params: {
    body: LoginRequest
  }): Observable<StrictHttpResponse<LoginResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EntitlementService.EntitlementControllerCreateLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponse>;
      })
    );
  }

  /**
   * Create login record from IjwtUserInfo.
   *
   * Record the users login details from the IjwtUserInfo object.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `entitlementControllerCreateLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitlementControllerCreateLogin(params: {
    body: LoginRequest
  }): Observable<LoginResponse> {

    return this.entitlementControllerCreateLogin$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResponse>) => r.body as LoginResponse)
    );
  }

}
