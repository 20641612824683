<div class="flex flex-col px-4 py-4 w-full h-full">
  <div>
    <h1 class="text-3xl">User Management</h1>
  </div>
  <div>
    <form class="flex items-center  sm:flex-wrap sm:[&>*]:w-full " #formFilters="ngForm"
      (ngSubmit)="filterRecords(formFilters.value)">
      <mat-form-field class="">
        <mat-label>Search User</mat-label>
        <input type="search" ngModel name="searchText" matInput placeholder="Ex. andrew" value="">
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Status</mat-label>
        <mat-select ngModel name="status">
          <mat-option>-- ALL --</mat-option>
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Inactive">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Organization</mat-label>
        <mat-select ngModel name="organizationId">
          <mat-option>-- ALL --</mat-option>
          <mat-option *ngFor="let organization of organizations"
            [value]="organization.id">{{organization.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-button color="primary" class="primary-button-bg mx-2">
        <mat-icon>search</mat-icon>
        Search
      </button>
    </form>
  </div>
  <div class="w-full my-2 overflow-y-visible sm:flex-grow overflow-x-auto sm:overflow-y-hidden">
    <table class="table-auto w-full text-left my-2 min-w-[800px] [&>*_td]:pb-1">
      <thead>
        <tr class=" border-b-2 border-b-slate-200">
          <th class="w-[50px]">
            <mat-checkbox [indeterminate]="isSomeChecked()" [checked]="isAllChecked()"
              (change)="checkAll()"></mat-checkbox>
          </th>
          <th class="">
            <div class="flex justify-between items-center"><span>User Name & Email</span>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="deleteSelected()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Selected</span>
                  </button>
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="updateUsersStatus('Active')">
                    <mat-icon>done_all</mat-icon>
                    <span>Activate Selected</span>
                  </button>
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="updateUsersStatus('Inactive')">
                    <mat-icon>remove_done</mat-icon>
                    <span>Inactive Selected</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </th>
          <th>
            Full Name
          </th>
          <th>
            Status
          </th>
          <th>
            Organization
          </th>
          <th class="w-[300px]">
            <div class="flex justify-between items-center ">
              <span>Action</span>
              <button mat-button matTooltip="Add New User" (click)="addNewUserClick()"><mat-icon>add</mat-icon>Add New
                User</button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="font-medium">
        <tr *ngFor="let user of users" class="hover:bg-slate-200">
          <td>
            <mat-checkbox [checked]="isChecked(user.id)" (change)="checkUncheck(user.id)"></mat-checkbox>
          </td>
          <td class="position--relative overflow-hidden">
            <span>{{user.name}}</span><br />
            <small class="absolute mt-[-5px] text-xs font-thin ">{{user.email}}</small>
          </td>
          <td>
            {{user.firstName}} {{user.lastName}}
          </td>
          <td>
            {{user.status}}
          </td>
          <td>
            {{user.organizationName}}
          </td>
          <td>
            <button mat-button matTooltip="Edit User"
              (click)="editUserClick(user.id)"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button matTooltip="Delete User"
              (click)="deleteUser(user.id)"><mat-icon>delete</mat-icon>Delete</button>
            <button mat-button matTooltip="Activate User" (click)="updateUsersStatus('Active',user.id)"
              *ngIf="user.status!=='Active'"><mat-icon>done_all</mat-icon>Activate</button>
            <button mat-button matTooltip="Deactivate User" (click)="updateUsersStatus('Inactive',user.id)"
              *ngIf="user.status==='Active'"><mat-icon>remove_done</mat-icon>Deactivate</button>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <div class="flex flex-col flex-grow flex-shrink">
    <mat-divider></mat-divider>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"
      style="background-color:unset;">
    </mat-paginator>
  </div>
</div>