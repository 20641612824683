import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../../../services/admin/admin.service';
import {
  MembershipTypeDto,
  OrganizationDto,
} from '../../../services/admin/admin_dto';

@Component({
  selector: 'fse-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss'],
})
export class EditOrganizationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditOrganizationComponent>,
    @Inject(MAT_DIALOG_DATA) public currentData: OrganizationDto,
    public adminService: AdminService
  ) {
    this.currentData.membershipExpire = new Date(
      this.currentData.membershipExpire
    );
    console.log(this.currentData);
  }
  async ngOnInit() {
    this.memberships = await this.adminService.getMembershipTypes();
  }

  memberships: MembershipTypeDto[] = [];
  onSubmitForm(formData: OrganizationDto) {
    console.log(formData);
    this.adminService
      .UpdateOrganization({ ...this.currentData, ...formData })
      .then((data) => {
        this.dialogRef.close(data);
      });
  }
}
