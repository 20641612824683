/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, tap, throwError } from 'rxjs';
import { AUTHENTICATION_CALLBACK_URL_FAILED } from '../shared/constants/constant';
import { SharedService } from '../shared/services/shared.service';
import { environment } from '../../environments/environment';
import { CsrfTokenHeaderName, getCsrfTokenFromLocalStorage, setCsrfHeader } from '../csrf/csrf';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedService, private router: Router) {}
  
  excludeUrl = ['/dashboard'];

   intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const rToken = getCsrfTokenFromLocalStorage();
    if (rToken && !req.headers.has(CsrfTokenHeaderName)) {
      req = req.clone({
        headers: setCsrfHeader(req.headers),
        withCredentials: true,
      });
    } else {
      req = req.clone({
        withCredentials: true,
      });
    }



    return next.handle(req).pipe(
      tap({
        next: () => {
          this.sharedService.validToken.next({ validToken: true });
        },
        error: async (error: HttpErrorResponse) => {
     
          if (error.status === 401) {
              await this.sharedService.logout();
              window.location.href = `${environment.bffUrl}/login`;
          } else if (
            (error.status === 400 || error.status === 500) &&
            error.error &&
            error.error.message &&
            error?.error?.message?.toLowerCase() ===
            AUTHENTICATION_CALLBACK_URL_FAILED.toLowerCase() &&
            !this.excludeUrl.includes(this.router.url.toLowerCase())
          ) {
            this.router.navigate(['/error-page/unauthorized']);

          } else {
            this.sharedService.validToken.next({ validToken: true });
          }
          return throwError(() => new Error('API Failed.'));
        },
      })
    );
  }
}
