
<div class="container">


    <mat-card  style="width:300px;margin:10px">
        <mat-card-header>
            <mat-card-title>{{profile?.firstName}} {{profile?.lastName}}</mat-card-title>
            <mat-card-subtitle>{{profile?.username}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
            <div class="spacer"></div>
        </mat-card-actions>
    </mat-card>

</div>


<div class="container">
    <table mat-table [dataSource]="servicesStatus | keyvalue" class="mat-elevation-z8" style="width:300px;margin:10px">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Microservice</th>
            <td mat-cell *matCellDef="let element"> {{element.value.name}} </td>
        </ng-container>
        <ng-container matColumnDef="health">
            <th mat-header-cell *matHeaderCellDef> Status</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.value.health" class="material-icons">check </span>
                <span *ngIf="!element.value.health" class="material-icons">report_problem</span>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="['name','health']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name','health'];"></tr>
    </table>


</div>




<div class="container">


    <mat-card  style="margin:10px">
        <mat-card-header>
            <mat-card-title>Asynchronous Messaging</mat-card-title>
            <mat-card-subtitle>Via message queue & web-sockets</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <span >Call REST API of Chat microservice.This will construct a sentence and send each word via queue and websocket to the ui</span>

            <mat-card style="max-width:300px;padding:10px;margin:10px;background-color:darkcyan;color:aliceblue" *ngFor="let sentenceFromBot of sentencesFromBot">
                {{sentenceFromBot}}
            </mat-card >

        </mat-card-content>
        <mat-card-actions>
            <div class="spacer"></div>
            <button mat-raised-button color="accent" (click)="triggerQueueMessageViaMessageBusToBffToWebAppViaWebsocket()">Tell me!</button>
        </mat-card-actions>
    </mat-card>

    <mat-card  style="margin:10px">
        <mat-card-actions>
            <div class="spacer"></div>
            <button mat-raised-button color="accent" (click)="logout()">Logout</button>
        </mat-card-actions>
    </mat-card>

</div>



