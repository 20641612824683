<mat-card class="w-full relative group">
  <mat-card-header>
    <mat-card-title>Products [{{totalRecords}}]</mat-card-title>
  </mat-card-header>
  <mat-card-content class="overflow-auto">
    <table class="w-full text-left">
      <thead>
        <tr class="border-b border-b-slate-400">
          <th>Name</th>
          <th class="w-[100px]">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of products">
          <td>{{product.name}}</td>
          <td>{{product.status}}</td>
        </tr>
      </tbody>
    </table>
    <a class="absolute right-2 top-2 cursor-pointer invisible group-hover:visible" matTooltip="Edit"
      [routerLink]="['/admin/products']">
      <mat-icon>edit</mat-icon>
    </a>
  </mat-card-content>
</mat-card>