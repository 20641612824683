<div class="flex flex-col flex-grow flex-shrink w-full h-full gap-2 px-2 pt-2 pb-4">
  <div class="flex flex-grow flex-shrink gap-2 h-1/2  sm:flex-col sm:h-auto">
    <fse-summary-card class="flex w-[25%] sm:w-full" />
    <fse-activity-feed class="flex w-[25%] sm:w-full" />
    <fse-users-group-list class="flex w-[50%] sm:w-full" />
  </div>
  <div class="flex flex-grow flex-shrink gap-2 h-1/2 sm:flex-col sm:h-auto">
    <fse-products-card class="flex w-[25%] sm:w-full" />
    <fse-data-bar-chart class="flex w-[50%] sm:w-full" />
    <fse-data-doughnut-chart class="flex w-[25%] sm:w-full" />
  </div>
</div>