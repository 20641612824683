import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'fse-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  @Input() opened = false;
  @Output() openedChange = new EventEmitter<boolean>();

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }
  toggle: boolean[] = [];
  toggleSideBar() {
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
  }
  closeSideBar(e: boolean) {
    if (!e) {
      this.opened = false;
      this.openedChange.emit(this.opened);
    }
  }

  currentUrl = this.router.url;
}
