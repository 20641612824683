/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AngularLoggerService } from '@synopsys-inc/angular-logger';
// import { Socket } from 'ngx-socket-io';
import { Subject, fromEvent } from 'rxjs';
import { io } from 'socket.io-client';
import { SharedService } from '../shared/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  receivedProjectRefreshEvent: Subject<any> = new Subject();
  receivedChamberDetailsRefreshEvent: Subject<any> = new Subject();
  receivedClusterRunningJobsRefreshEvent: Subject<any> = new Subject();
  receivedProjectsRunningClustersRefreshEvent: Subject<any> = new Subject();

  tokenHeaderName = 'x-csrf-token';
  socketInstance;

  constructor(private logger: AngularLoggerService, private sharedService: SharedService) {}

  initiateConnection() {
    this.logger.log(`Initiate connection to socket: '${this.sharedService.environmentDetails.webSocketUrl}'`);
    this.socketInstance = io(this.sharedService.environmentDetails.webSocketUrl, {
      reconnectionAttempts: 3,
      transports: ['websocket'],
      auth: {
        'x-csrf-token': sessionStorage.getItem(this.tokenHeaderName),
      },
    });

    this.connectionToSocket().subscribe((data) => {
      this.logger.log('Connection to socket established');
      this.logger.log('Connection to socket', data);
    },(error)=>{
      this.logger.error('Connection to socket failed');
      this.logger.error('Connection to socket', error);
    });
  }

  initiateDisconnection() {
    this.disconnectionFromSocket().subscribe((data) => {
      this.logger.log('disconnectionFromSocket received');
      this.logger.log('disconnectionFromSocket', data);
    });
  }

  connectionToSocket() {
    return fromEvent(this.socketInstance, 'connected');
  }

  disconnectionFromSocket() {
    return fromEvent(this.socketInstance, 'disconnected');
  }
  subscribeToSocketEvent(event: string) {
    return fromEvent(this.socketInstance, event);
  }

  public messageSubject:Subject<{data:any}>=new Subject()
  subscribeToSocketEventTest() {
    /**/
    fromEvent(this.socketInstance, 'message-from-bff-to-webapp').subscribe((res: any) => {
      this.logger.log('Received message-from-bff-to-webapp '+JSON.stringify(res));
      this.messageSubject.next(res)
    });
  }
  emitToSocketEvent(event: string, data: any) {
    return this.socketInstance.emit(event, data);
  }
}
