<div class="flex gap-4 p-2 h-screen pb-[70px] sm:flex-col sm:overflow-visible sm:h-auto sm:pb-2">
  <div class="w-1/6 sm:w-full">
    <div class="flex justify-between pr-3 pt-3">
      <button mat-raised-button color="accent" class="mb-2" (click)="startNewChat()">New chat</button>
      <button mat-raised-button class="!hidden sm:!block" [matBadge]="sessions.length"
        (click)="toggleChatSession=!toggleChatSession">
        {{toggleChatSession?"Hide":"Show"}} Chat Sessions
      </button>
    </div>
    <div class="flex flex-col" [ngClass]="{'sm:hidden':!toggleChatSession}">
      <div class="flex border bg-gray-400 hover:bg-gray-500" *ngIf="!chatSession">
        <a class="px-1.5 py-2 flex-grow flex-shrink cursor-pointer" [routerLink]="['/chat',sessionID]">
          Current chat
        </a>
      </div>
      <div class="flex border hover:bg-gray-200"
        [ngClass]="{'bg-gray-400 hover:bg-gray-500': session.session_id === sessionID }"
        *ngFor="let session of sessions">
        <a class="px-1.5 py-2 flex-grow flex-shrink cursor-pointer overflow-hidden text-ellipsis"
          [routerLink]="['/chat', session.session_id]">
          {{session.session_title}}
        </a>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteChatHistory(session.session_id)">
            <mat-icon>delete</mat-icon>
            <span>Delete Chat History</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="grow w-3/6 sm:w-full sm:h-[80vh]">
    <fse-chat-pane [messages]="messages" (prompt)="sendPrompt($event)" [sessionID]="sessionID" />
  </div>
  <div class="grow w-2/6 sm:w-full">
    <fse-reference-pane />
  </div>
</div>