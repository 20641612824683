/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ChatMicroserviceApiService } from './services/chat-microservice-api.service';
import { LlmService } from './services/llm.service';
import { EntitlementService } from './services/entitlement.service';
import { UsersService } from './services/users.service';
import { ChatService } from './services/chat.service';
import { AdminService } from './services/admin.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ChatMicroserviceApiService,
    LlmService,
    EntitlementService,
    UsersService,
    ChatService,
    AdminService,
    ApiConfiguration
  ],
})
export class ChatMicroserviceApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ChatMicroserviceApiModule> {
    return {
      ngModule: ChatMicroserviceApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ChatMicroserviceApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ChatMicroserviceApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
