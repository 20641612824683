import { Component, OnInit } from '@angular/core';
import {
  MembershipTypeDto,
  OrganizationDto,
} from '../../../services/admin/admin_dto';
import { AdminService } from '../../../services/admin/admin.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fse-add-new-organization',
  templateUrl: './add-new-organization.component.html',
  styleUrls: ['./add-new-organization.component.scss'],
})
export class AddNewOrganizationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddNewOrganizationComponent>,
    public adminService: AdminService
  ) {}
  async ngOnInit() {
    this.memberships = await this.adminService.getMembershipTypes();
  }

  memberships: MembershipTypeDto[] = [];
  onSubmitForm(formData: OrganizationDto) {
    console.log(formData);
    this.adminService.addNewOrganization(formData).then((data) => {
      this.dialogRef.close(data);
    });
  }
}
