/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddMessageDto } from '../models/add-message-dto';
import { AddSessionDto } from '../models/add-session-dto';
import { ChatMessage } from '../models/chat-message';
import { ChatSession } from '../models/chat-session';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation chatControllerFindSessionsForUserEmail
   */
  static readonly ChatControllerFindSessionsForUserEmailPath = '/chat/sessions/{user_email}';

  /**
   * Find sessions for a login.
   *
   * Fetch chat sessions for a specific login.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatControllerFindSessionsForUserEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatControllerFindSessionsForUserEmail$Response(params: {
    user_email: string;
  }): Observable<StrictHttpResponse<Array<ChatSession>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.ChatControllerFindSessionsForUserEmailPath, 'get');
    if (params) {
      rb.path('user_email', params.user_email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChatSession>>;
      })
    );
  }

  /**
   * Find sessions for a login.
   *
   * Fetch chat sessions for a specific login.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatControllerFindSessionsForUserEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatControllerFindSessionsForUserEmail(params: {
    user_email: string;
  }): Observable<Array<ChatSession>> {

    return this.chatControllerFindSessionsForUserEmail$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatSession>>) => r.body as Array<ChatSession>)
    );
  }

  /**
   * Path part for operation chatControllerFindSession
   */
  static readonly ChatControllerFindSessionPath = '/chat/session/{sessionId}';

  /**
   * Find a session by ID.
   *
   * Fetch chat messages for a specific session by its ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatControllerFindSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatControllerFindSession$Response(params: {
    sessionId: string;
  }): Observable<StrictHttpResponse<Array<ChatMessage>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.ChatControllerFindSessionPath, 'get');
    if (params) {
      rb.path('sessionId', params.sessionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChatMessage>>;
      })
    );
  }

  /**
   * Find a session by ID.
   *
   * Fetch chat messages for a specific session by its ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatControllerFindSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatControllerFindSession(params: {
    sessionId: string;
  }): Observable<Array<ChatMessage>> {

    return this.chatControllerFindSession$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatMessage>>) => r.body as Array<ChatMessage>)
    );
  }

  /**
   * Path part for operation chatControllerDeleteSession
   */
  static readonly ChatControllerDeleteSessionPath = '/chat/session/{sessionId}';

  /**
   * Delete a session by ID.
   *
   * Delete a chat session by its ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatControllerDeleteSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatControllerDeleteSession$Response(params: {
    sessionId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.ChatControllerDeleteSessionPath, 'delete');
    if (params) {
      rb.path('sessionId', params.sessionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a session by ID.
   *
   * Delete a chat session by its ID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatControllerDeleteSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chatControllerDeleteSession(params: {
    sessionId: string;
  }): Observable<void> {

    return this.chatControllerDeleteSession$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation chatControllerCreateSession
   */
  static readonly ChatControllerCreateSessionPath = '/chat/session';

  /**
   * Create a new session.
   *
   * Create a new chat session.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatControllerCreateSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatControllerCreateSession$Response(params: {
    body: AddSessionDto
  }): Observable<StrictHttpResponse<ChatSession>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.ChatControllerCreateSessionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatSession>;
      })
    );
  }

  /**
   * Create a new session.
   *
   * Create a new chat session.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatControllerCreateSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatControllerCreateSession(params: {
    body: AddSessionDto
  }): Observable<ChatSession> {

    return this.chatControllerCreateSession$Response(params).pipe(
      map((r: StrictHttpResponse<ChatSession>) => r.body as ChatSession)
    );
  }

  /**
   * Path part for operation chatControllerCreateMessage
   */
  static readonly ChatControllerCreateMessagePath = '/chat/message';

  /**
   * Create a new message.
   *
   * Create a new chat message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chatControllerCreateMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatControllerCreateMessage$Response(params: {
    body: AddMessageDto
  }): Observable<StrictHttpResponse<Array<ChatMessage>>> {

    const rb = new RequestBuilder(this.rootUrl, ChatService.ChatControllerCreateMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChatMessage>>;
      })
    );
  }

  /**
   * Create a new message.
   *
   * Create a new chat message.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chatControllerCreateMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  chatControllerCreateMessage(params: {
    body: AddMessageDto
  }): Observable<Array<ChatMessage>> {

    return this.chatControllerCreateMessage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChatMessage>>) => r.body as Array<ChatMessage>)
    );
  }

}
