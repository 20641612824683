<form #organizationDetail="ngForm" class="flex flex-col w-full h-full pb-4"
  (ngSubmit)="onSubmitForm(organizationDetail.value)">
  <h1 mat-dialog-title>Edit Organization</h1>
  <div mat-dialog-content class="flex-grow flex-shrink !max-h-[unset] overflow-visible">
    <h1>Organization Detail</h1>
    <mat-form-field class="w-full">
      <mat-label>Organization Name</mat-label>
      <input type="text" name="name" required="*" matInput placeholder="Enter Product Name Here"
        [ngModel]="currentData.name">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Email-ID</mat-label>
      <input type="email" name="email" required="*" matInput placeholder="Ex. company@gmail.com"
        [ngModel]="currentData.email">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Phone</mat-label>
      <input type="text" name="phone" required="*" matInput placeholder="+01 1234567890" [ngModel]="currentData.phone">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Users Count</mat-label>
      <input type="number" name="users" required="*" matInput placeholder="Enter Users Count"
        [ngModel]="currentData.users">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Membership Type</mat-label>
      <mat-select required="*" name="membershipId" [ngModel]="currentData.membershipId">
        <mat-option *ngFor="let membership of memberships" class="MembershipOther"
          [value]="membership.id">{{membership.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Membership Expire on</mat-label>
      <input readonly name="membershipExpire" required="*" matInput [matDatepicker]="picker" placeholder="Expiry Date"
        [ngModel]="currentData.membershipExpire">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Product Status</mat-label>
      <mat-select required="*" name="status" [ngModel]="currentData.status">
        <mat-option value="Active">Active</mat-option>
        <mat-option value="Inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div mat-dialog-actions align="center">
    <button mat-button class="primary-button-bg" [disabled]="!organizationDetail.valid">Save</button>
    <button mat-button cdkFocusInitial mat-dialog-close>Cancel</button>
  </div>
</form>