import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'fse-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit{

  constructor(private sharedService:SharedService){
    
  }

  async ngOnInit() {
    
    await this.sharedService.logout();
  }
}
