/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Chats } from '../models/chats';
import { Organization } from '../models/organization';
import { OrganizationDto } from '../models/organization-dto';
import { Product } from '../models/product';
import { ProductDto } from '../models/product-dto';
import { ProductGroup } from '../models/product-group';
import { SetProductStatusDto } from '../models/set-product-status-dto';
import { UserFeedback } from '../models/user-feedback';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminDataControllerGetAllProducts
   */
  static readonly AdminDataControllerGetAllProductsPath = '/admin/productslist';

  /**
   * Get all products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetAllProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllProducts$Response(params?: {
    pageIndex?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: string;
    searchText?: string;
    status?: string;
    version?: string;
    groupId?: number;
  }): Observable<StrictHttpResponse<Array<Product>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetAllProductsPath, 'get');
    if (params) {
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortField', params.sortField, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('searchText', params.searchText, {});
      rb.query('status', params.status, {});
      rb.query('version', params.version, {});
      rb.query('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Product>>;
      })
    );
  }

  /**
   * Get all products.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetAllProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllProducts(params?: {
    pageIndex?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: string;
    searchText?: string;
    status?: string;
    version?: string;
    groupId?: number;
  }): Observable<Array<Product>> {

    return this.adminDataControllerGetAllProducts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Product>>) => r.body as Array<Product>)
    );
  }

  /**
   * Path part for operation adminDataControllerGetProductsCount
   */
  static readonly AdminDataControllerGetProductsCountPath = '/admin/productscount';

  /**
   * Get a total products in db.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetProductsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetProductsCount$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetProductsCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get a total products in db.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetProductsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetProductsCount(params?: {
  }): Observable<void> {

    return this.adminDataControllerGetProductsCount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminDataControllerGetProductGroups
   */
  static readonly AdminDataControllerGetProductGroupsPath = '/admin/productgroups';

  /**
   * Get products groups listing for filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetProductGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetProductGroups$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ProductGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetProductGroupsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProductGroup>>;
      })
    );
  }

  /**
   * Get products groups listing for filter.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetProductGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetProductGroups(params?: {
  }): Observable<Array<ProductGroup>> {

    return this.adminDataControllerGetProductGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProductGroup>>) => r.body as Array<ProductGroup>)
    );
  }

  /**
   * Path part for operation adminDataControllerGetProductById
   */
  static readonly AdminDataControllerGetProductByIdPath = '/admin/products/{id}';

  /**
   * Get a product by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetProductById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetProductById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Product>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetProductByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Product>;
      })
    );
  }

  /**
   * Get a product by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetProductById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetProductById(params: {
    id: number;
  }): Observable<Product> {

    return this.adminDataControllerGetProductById$Response(params).pipe(
      map((r: StrictHttpResponse<Product>) => r.body as Product)
    );
  }

  /**
   * Path part for operation adminDataControllerUpdateProduct
   */
  static readonly AdminDataControllerUpdateProductPath = '/admin/products/{id}';

  /**
   * Update a product.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerUpdateProduct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerUpdateProduct$Response(params: {
    id: number;
    body: ProductDto
  }): Observable<StrictHttpResponse<Product>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerUpdateProductPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Product>;
      })
    );
  }

  /**
   * Update a product.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerUpdateProduct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerUpdateProduct(params: {
    id: number;
    body: ProductDto
  }): Observable<Product> {

    return this.adminDataControllerUpdateProduct$Response(params).pipe(
      map((r: StrictHttpResponse<Product>) => r.body as Product)
    );
  }

  /**
   * Path part for operation adminDataControllerAddProduct
   */
  static readonly AdminDataControllerAddProductPath = '/admin/products';

  /**
   * Add a new product.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerAddProduct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerAddProduct$Response(params: {
    body: ProductDto
  }): Observable<StrictHttpResponse<Product>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerAddProductPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Product>;
      })
    );
  }

  /**
   * Add a new product.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerAddProduct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerAddProduct(params: {
    body: ProductDto
  }): Observable<Product> {

    return this.adminDataControllerAddProduct$Response(params).pipe(
      map((r: StrictHttpResponse<Product>) => r.body as Product)
    );
  }

  /**
   * Path part for operation adminDataControllerDeleteProduct
   */
  static readonly AdminDataControllerDeleteProductPath = '/admin/deleteproducts';

  /**
   * Delete list of products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerDeleteProduct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerDeleteProduct$Response(params: {
    body: Array<number>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerDeleteProductPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete list of products.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerDeleteProduct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerDeleteProduct(params: {
    body: Array<number>
  }): Observable<void> {

    return this.adminDataControllerDeleteProduct$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminDataControllerSetProductStatus
   */
  static readonly AdminDataControllerSetProductStatusPath = '/admin/setproductstatus';

  /**
   * Set products status Active/Inactive.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerSetProductStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerSetProductStatus$Response(params: {
    body: SetProductStatusDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerSetProductStatusPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set products status Active/Inactive.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerSetProductStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerSetProductStatus(params: {
    body: SetProductStatusDto
  }): Observable<void> {

    return this.adminDataControllerSetProductStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminDataControllerGetAllOrganizations
   */
  static readonly AdminDataControllerGetAllOrganizationsPath = '/admin/organizations';

  /**
   * Get filtered organizations list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetAllOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllOrganizations$Response(params?: {
    pageIndex?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: string;
    searchText?: string;
    status?: string;
  }): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetAllOrganizationsPath, 'get');
    if (params) {
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortField', params.sortField, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('searchText', params.searchText, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Get filtered organizations list.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetAllOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllOrganizations(params?: {
    pageIndex?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: string;
    searchText?: string;
    status?: string;
  }): Observable<Array<Organization>> {

    return this.adminDataControllerGetAllOrganizations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Path part for operation adminDataControllerAddOrganization
   */
  static readonly AdminDataControllerAddOrganizationPath = '/admin/organizations';

  /**
   * Add a new organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerAddOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerAddOrganization$Response(params: {
    body: OrganizationDto
  }): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerAddOrganizationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Add a new organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerAddOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerAddOrganization(params: {
    body: OrganizationDto
  }): Observable<Organization> {

    return this.adminDataControllerAddOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation adminDataControllerGetOrganizationById
   */
  static readonly AdminDataControllerGetOrganizationByIdPath = '/admin/organizations/{id}';

  /**
   * Get an organization by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetOrganizationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetOrganizationById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetOrganizationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Get an organization by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetOrganizationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetOrganizationById(params: {
    id: number;
  }): Observable<Organization> {

    return this.adminDataControllerGetOrganizationById$Response(params).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation adminDataControllerUpdateOrganization
   */
  static readonly AdminDataControllerUpdateOrganizationPath = '/admin/organizations/{id}';

  /**
   * Update an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerUpdateOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerUpdateOrganization$Response(params: {
    id: number;
    body: OrganizationDto
  }): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerUpdateOrganizationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Update an organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerUpdateOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminDataControllerUpdateOrganization(params: {
    id: number;
    body: OrganizationDto
  }): Observable<Organization> {

    return this.adminDataControllerUpdateOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation adminDataControllerDeleteOrganization
   */
  static readonly AdminDataControllerDeleteOrganizationPath = '/admin/organizations/{id}';

  /**
   * Delete an organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerDeleteOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerDeleteOrganization$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerDeleteOrganizationPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete an organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerDeleteOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerDeleteOrganization(params: {
    id: number;
  }): Observable<void> {

    return this.adminDataControllerDeleteOrganization$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation adminDataControllerGetAllChats
   */
  static readonly AdminDataControllerGetAllChatsPath = '/admin/chats';

  /**
   * Get all chat details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetAllChats()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllChats$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Chats>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetAllChatsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Chats>>;
      })
    );
  }

  /**
   * Get all chat details.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetAllChats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllChats(params?: {
  }): Observable<Array<Chats>> {

    return this.adminDataControllerGetAllChats$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Chats>>) => r.body as Array<Chats>)
    );
  }

  /**
   * Path part for operation adminDataControllerGetAllFeedback
   */
  static readonly AdminDataControllerGetAllFeedbackPath = '/admin/feedback';

  /**
   * Get all user feedback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDataControllerGetAllFeedback()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllFeedback$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserFeedback>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminDataControllerGetAllFeedbackPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserFeedback>>;
      })
    );
  }

  /**
   * Get all user feedback.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminDataControllerGetAllFeedback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDataControllerGetAllFeedback(params?: {
  }): Observable<Array<UserFeedback>> {

    return this.adminDataControllerGetAllFeedback$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserFeedback>>) => r.body as Array<UserFeedback>)
    );
  }

}
