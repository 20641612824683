<h2 class="text-center text-2xl py-3  tracking-[0.3em]">ADMIN</h2>
<mat-divider></mat-divider>
<div
  class="flex flex-col [&>*]:text-white [&>*]:px-4 [&>*]:py-2 [&>*]:border [&>*]:border-gray-400  [&>*:hover]:bg-gray-900">
  <a style="cursor:pointer" [routerLink]="['/admin/dashboard']"
    [ngClass]="{'bg-gray-900': currentUrl === '/admin/dashboard'}">Dashboard</a>
  <a style="cursor:pointer" [routerLink]="['/admin/products']"
    [ngClass]="{'bg-gray-900': currentUrl === '/admin/products'}">Products</a>
  <a style="cursor:pointer" [routerLink]="['/admin/organizations']"
    [ngClass]="{'bg-gray-900': currentUrl === '/admin/organizations'}">Organizations</a>
  <a style="cursor:pointer" [routerLink]="['/admin/users']"
    [ngClass]="{'bg-gray-900': currentUrl === '/admin/users'}">Users</a>
  <a style="cursor:pointer" [routerLink]="['/admin/chats']"
    [ngClass]="{'bg-gray-900': currentUrl === '/admin/chats'}">Chats</a>
  <a style="cursor:pointer" [routerLink]="['/admin/response-review']"
    [ngClass]="{'bg-gray-900': currentUrl === '/admin/response-review'}">Response Review</a>
</div>