
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  production: true,
  bffUrl: 'https://ka-qa.synopsys.com',
  servicesUrl: 'https://ka-qa.synopsys.com/api',
  logger: {
    level: 1,
    serverLogLevel: NgxLoggerLevel.LOG,
    enableSourceMaps: true,
  },
  acceptedDomain: [{ value: 'synopsys.com' }],
  socketsEnabled: true,


};
