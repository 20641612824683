import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../../../services/admin/admin.service';
import { OrganizationDto, UserDto } from '../../../services/admin/admin_dto';

@Component({
  selector: 'fse-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public currentData: UserDto,
    public adminService: AdminService
  ) {
    console.log(this.currentData);
  }
  async ngOnInit() {
    this.organizations = await this.adminService.getOrganizations({
      pageSize: 0,
    });
  }

  organizations: OrganizationDto[] = [];

  onSubmitForm(formData: UserDto) {
    console.log(formData);
    this.adminService
      .UpdateUser({ ...this.currentData, ...formData })
      .then((data) => {
        this.dialogRef.close(data);
      });
  }
}
