import { Injectable } from '@angular/core';
import { BffApiService } from '@snps-webapp/angular-api-client/bff';
import { EntitlementService } from '@snps-webapp/angular-api-client/chat-microservice';
import { AngularLoggerService } from '@synopsys-inc/angular-logger';
import { lastValueFrom } from 'rxjs';
import { SharedService } from './shared/services/shared.service';
import { SocketsService } from './services/socket.service';
import { storeCsrfTokenInLocalStorage } from './csrf/csrf';
import { IJWTUserInfo } from '@synopsys-inc/ms-common-lib';

type SessionLoginData = {
    profile: IJWTUserInfo;
    login: { loginId: number; };
};
export function initializeAppFactory(appInitService: AppInitService) {
    return async () => { await appInitService.init() };
}

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    constructor(
        private sharedService: SharedService,
        private bffApiService: BffApiService,
        private entitlementService: EntitlementService,
        private logger: AngularLoggerService,
        private socketService: SocketsService,
    ) { }

    // Main initialization method
    async init() {
        this.logger.log("Setting up connection");
        await this.retrieveAndStoreCsrfToken();
        await this.getAndSetEnvironmentDetails();
        this.setupWebSocketConnection();
        await this.handleUserLogin();
    }


    // Retrieves CSRF token and stores it in local storage
    private async retrieveAndStoreCsrfToken() {
        this.logger.log("Getting CSRF Token");
        const csrfResponse = await lastValueFrom(this.bffApiService.appControllerGetCsrfToken());
        storeCsrfTokenInLocalStorage(csrfResponse.token);
        this.logger.log("Got CSRF Token :" + csrfResponse.token);
    }

    // Retrieves environment details and sets them in the shared service
    private async getAndSetEnvironmentDetails() {
        this.logger.log("Getting Environment Details");
        this.sharedService.environmentDetails = await lastValueFrom(this.bffApiService.appControllerGetEnvironmentData());
        this.logger.log("Got Environment Details :" + JSON.stringify(this.sharedService.environmentDetails));
    }

 

    // Sets up WebSocket connection
    private setupWebSocketConnection() {
        this.logger.log("Setting up websocket connection");
        this.socketService.initiateConnection();
        this.socketService.subscribeToSocketEventTest(); // Assuming this method is meant for setting up subscriptions
    }

    // Handles user login process
    private async handleUserLogin() {
        this.logger.log("Getting User Info");
        const userResponse = await this.getUserInfo();
        sessionStorage.setItem('userInfo', JSON.stringify(userResponse));
        this.logger.log("Got User Info :" + JSON.stringify(userResponse));

        if (await this.shouldUpdateSessionLogin(userResponse)) {
            await this.createAndUpdateSessionLogin(userResponse);
        } else {
            this.logger.log("Session login data is up-to-date.");
        }
    }

    // Retrieves user information
    private async getUserInfo() {
        return await lastValueFrom(this.bffApiService.appControllerGetProfile());
    }

    // Determines if session login needs an update
    private async shouldUpdateSessionLogin(userResponse: IJWTUserInfo): Promise<boolean> {
        const sessionLoginData = sessionStorage.getItem('sessionLogin');
        if (sessionLoginData) {
            const parsedSessionLoginData: SessionLoginData = JSON.parse(sessionLoginData);
            return parsedSessionLoginData.profile.email !== userResponse.email;
        }
        return true;
    }

    // Creates a new session login and updates session storage
    private async createAndUpdateSessionLogin(userResponse: IJWTUserInfo) {
        this.logger.log("Creating Login Request");
        const loginResponse = await this.createLoginRequest(userResponse);
        const newSessionLoginData: SessionLoginData = {
            profile: userResponse,
            login: { loginId: loginResponse.loginId }
        };
        sessionStorage.setItem('sessionLogin', JSON.stringify(newSessionLoginData));
        this.logger.log("Updated session login data");
    }
    // Creates a login request and returns the response
    private async createLoginRequest(userResponse: IJWTUserInfo) {
      try {
                const request = {
                    email: userResponse.email,
                    firstName: userResponse.firstName,
                    lastName: userResponse.lastName
                };
                return await lastValueFrom(this.entitlementService.entitlementControllerCreateLogin({ body: request }));
      } catch (error) {
          this.logger.log("Error creating login request" + error);
      }
    }
}
