import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BffApiService,
  IjwtUserInfo,
} from '@snps-webapp/angular-api-client/bff';

@Component({
  selector: 'fse-snps-webapp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private bffApiService: BffApiService) {
    this.bffApiService.appControllerGetProfile().subscribe((data) => {
      console.log('===================================');
      console.log('this.bffApiService.appControllerGetProfile');
      console.log(data);
      this.userProfile = data;
    });
  }

  @Input() sidebarOpened = false;
  @Output() sidebarOpenedChange = new EventEmitter<boolean>();

  userProfile: IjwtUserInfo;

  toggleSideBar() {
    this.sidebarOpened = !this.sidebarOpened;
    this.sidebarOpenedChange.emit(this.sidebarOpened);
  }

  profileOptions = [
    {
      id: 1,
      name: 'Profile',
      routerLink: '',
    },
    {
      id: 2,
      name: 'Logout',
      routerLink: '/logout',
    },
  ];
}
