import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin/admin.service';

@Component({
  selector: 'fse-products-card',
  templateUrl: './products-card.component.html',
  styleUrls: ['./products-card.component.scss'],
})
export class ProductsCardComponent implements OnInit {
  constructor(public adminService: AdminService) {}
  async ngOnInit() {
    this.products = await this.adminService.getProducts({
      pageSize: 8,
    });
    this.totalRecords = await this.adminService.getTotalProducts();
  }
  products: ProductDTO[] = [];
  totalRecords = 0;
}
export interface ProductDTO {
  id: string;
  name: string;
  version: string;
  status: string;
  group: string;
}
