import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteOrganizationComponent } from './confirm-delete-organization/confirm-delete-organization.component';
import { AdminService } from '../../services/admin/admin.service';
import {
  IFormFilters,
  MembershipTypeDto,
  OrganizationDto,
} from '../../services/admin/admin_dto';
import { PageEvent } from '@angular/material/paginator';
import { AddNewOrganizationComponent } from './add-new-organization/add-new-organization.component';
import { EditOrganizationComponent } from './edit-organization/edit-organization.component';

@Component({
  selector: 'fse-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent implements OnInit {
  constructor(public dialog: MatDialog, public adminService: AdminService) {}
  async ngOnInit() {
    this.memberships = await this.adminService.getMembershipTypes();
    await this.updateOrganizationList();
  }

  memberships: MembershipTypeDto[] = [];
  organizations: OrganizationDto[] = [];
  totalRecords = 0;
  pageSize = 10;
  pageIndex = 0;
  filters: IFormFilters = {};
  checkedItems: string[] = [];
  checkUncheck(id: string) {
    if (this.checkedItems.includes(id)) {
      this.checkedItems = this.checkedItems.filter((x) => x !== id);
    } else {
      this.checkedItems.push(id);
    }
  }
  checkAll() {
    if (this.checkedItems.length === this.organizations.length) {
      this.checkedItems = [];
    } else {
      this.checkedItems = this.organizations.map((x) => x.id);
    }
  }

  isChecked(id: string) {
    return this.checkedItems.includes(id);
  }
  isAllChecked() {
    return (
      this.organizations.length > 0 &&
      this.checkedItems.length === this.organizations.length
    );
  }
  isSomeChecked(): boolean {
    return (
      this.checkedItems.length > 0 &&
      this.checkedItems.length !== this.organizations.length
    );
  }

  deleteOrganization(id: string): void {
    const dialogRef = this.dialog.open(ConfirmDeleteOrganizationComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.adminService.deleteOrganizations([id]).then((result) => {
          if (result) {
            this.updateOrganizationList();
            this.checkedItems = this.checkedItems.filter((x) => x != id);
          }
        });
      }
    });
  }
  async handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.updateOrganizationList();
  }
  async filterRecords(data: IFormFilters) {
    console.log({ data });
    const { membershipIds, status } = data;
    this.pageIndex = 0;
    this.filters = { membershipIds, status };
    this.updateOrganizationList();
  }
  async updateOrganizationList() {
    this.organizations = await this.adminService.getOrganizations(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      },
      this.filters
    );
    this.totalRecords = await this.adminService.getTotalOrganizations(
      this.filters
    );
  }
  async addNewOrganizationClick() {
    const dialogRef = this.dialog.open(AddNewOrganizationComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
      maxWidth: '500px',
      maxHeight: '800px',
      height: '100%',
      width: '100%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log({ result });
        this.updateOrganizationList();
      }
    });
  }
  async editOrganizationClick(id: string) {
    const data = this.organizations.find((x) => x.id === id);
    const dialogRef = this.dialog.open(EditOrganizationComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
      maxWidth: '500px',
      maxHeight: '800px',
      height: '100%',
      width: '100%',
      data: { ...data },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateOrganizationList();
      }
    });
  }
  async deleteSelected() {
    if (this.checkedItems.length) {
      this.adminService
        .deleteOrganizations(this.checkedItems)
        .then((result) => {
          if (result) {
            this.updateOrganizationList();
            this.checkedItems = [];
          }
        });
    }
  }
  async updateOrganizationsStatus(status: 'Active' | 'Inactive', id?: string) {
    const ids = id ? [id] : this.checkedItems;
    if (ids.length) {
      this.adminService.setOrganizationsStatus(ids, status).then(() => {
        this.updateOrganizationList();
      });
    }
  }
}
