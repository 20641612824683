import { Component, OnInit } from '@angular/core';
import { IjwtUserInfo } from '@snps-webapp/angular-api-client/bff/models/ijwt-user-info';

// Below services are generated from services swagger via npm run angular-client-api
import { BffApiService } from '@snps-webapp/angular-api-client/bff';
import {
  ChatMicroserviceApiService as ChatMicroserviceApiService,
  LlmService,
} from '@snps-webapp/angular-api-client/chat-microservice';
import { lastValueFrom, take } from 'rxjs';
import { SocketsService } from '../services/socket.service';
import { SharedService } from '../shared/services/shared.service';
import { environment } from '../../environments/environment';
import { UserManagementApiService } from '@snps-webapp/angular-api-client/user-management';
@Component({
  selector: 'snps-webapp-diagnostic-dashboard',
  templateUrl: './diagnostic-dashboard.component.html',
  styleUrls: ['./diagnostic-dashboard.component.scss'],
})
export class DiagnosticDashboardComponent implements OnInit {
  constructor(
    private bffApiService: BffApiService,
    private chatMicroserviceApiService: ChatMicroserviceApiService,
    private userManagementApiService: UserManagementApiService,
    private llmService: LlmService,
    private socketService: SocketsService,
    private sharedService: SharedService
  ) {
    this.servicesStatus = {};

    socketService.messageSubject.subscribe((msg) => {
      this.sentencesFromBot[this.sentencesFromBot.length - 1] =
        this.sentencesFromBot[this.sentencesFromBot.length - 1] +
        ' ' +
        msg.data;
    });
  }

  public sentencesFromBot = [];
  public profile: IjwtUserInfo = null;
  public servicesStatus: Record<string, { name: string; health: boolean }> = {};
  public services = ['CHAT_MICROSERVICE', 'USER_MANAGEMENT'];

  resetServiceStatus() {
    const getEmptyStatus = (name: string) => {
      return { name: name, health: false };
    };

    for (const service of this.services) {
      this.servicesStatus[service] = getEmptyStatus(service);
    }
  }

  getData() {
    this.bffApiService
      .appControllerGetProfile()
      .subscribe((profile: IjwtUserInfo) => {
        this.profile = profile;
      });

    this.resetServiceStatus();
    this.chatMicroserviceApiService
      .healthControllerCheck()
      .subscribe((data) => {
        this.servicesStatus['CHAT_MICROSERVICE'].health = true;
      });

    this.userManagementApiService.healthControllerCheck().subscribe((data) => {
      this.servicesStatus['USER_MANAGEMENT'].health = true;
    });
  }

  async triggerQueueMessageViaMessageBusToBffToWebAppViaWebsocket() {
    this.sentencesFromBot.push('');
    await lastValueFrom(
      this.llmService.llmControllerTriggerWsMessageToWebApp()
    );
    console.log('triggerQueueMessageViaMessageBusToBffToWebAppViaWebsocket');
  }

  async logout() {
    await lastValueFrom(this.bffApiService.authControllerLogout());
    await this.sharedService.logout();
    window.location.href = `${environment.bffUrl}/login`;
  }

  ngOnInit(): void {
    this.getData();
  }
}
