<mat-card class="w-full h-full p-4 flex flex-col justify-between">
  <mat-card-header>
    <mat-card-title>Chat</mat-card-title>
    <mat-card-subtitle
      >Your personal and company data are protected in this
      chat.</mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content class="overflow-y-auto flex w-full mb-4" #chatWindow>
    <div class="flex flex-col">
      <div
        *ngFor="let message of messages"
        [class.self-end]="message.sent_by === 0"
        [ngClass]="{ 'bg-neutral-100 w-1/2': message.sent_by === 0 }"
        class="w-full md:w-3/4 p-2 m-2 flex flex-col rounded-md"
      >
        <div class="font-semibold text-neutral-600">
          {{ message.sent_by === 1 ? 'ChatBot' : 'You' }}
        </div>
        <div [innerHTML]="message.context"></div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <form class="chat-form w-full flex items-center" (ngSubmit)="sendPrompt()" >
      <mat-form-field class="w-full">
        <mat-label>Prompt</mat-label>
        <input
          type="text"
          name="currentPrompt"
          [(ngModel)]="currentPrompt.context"
          matInput
          placeholder="Ex. What is the value of pi?"
        />
      </mat-form-field>
      <button
      [disabled]="!currentPrompt.context"
      style="margin-left: 10px"
      mat-raised-button
      color="accent"
      type="submit"
      (click)="sendPrompt()"
    >
      Send
    </button>
    </form>

  </mat-card-actions>
</mat-card>
