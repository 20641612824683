import { HttpHeaders } from "@angular/common/http";

export const CsrfTokenHeaderName = 'x-csrf-token';

export function setCsrfHeader(headers:HttpHeaders){
    if(headers){
        const token= getCsrfTokenFromLocalStorage()
        if(token){
            return headers.set(CsrfTokenHeaderName,token)
        }
        return headers
    }
}

export function getCsrfTokenFromLocalStorage(){
    return sessionStorage.getItem(CsrfTokenHeaderName);
}

export function storeCsrfTokenInLocalStorage(token){
    return sessionStorage.setItem(CsrfTokenHeaderName,token);
}

 