import { Component, OnInit } from '@angular/core';
import { DialogConfirmDeleteComponent } from './dialog-confirm-delete/dialog-confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { IFormFilters } from '../../services/admin/admin_dto';

import { PageEvent } from '@angular/material/paginator';
import { AddNewProductComponent } from './add-new-product/add-new-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';

// @Govind, I have aliased it here because of the name of your original service
import {
  AdminService,
  Product,
  ProductGroup,
} from '@snps-webapp/angular-api-client/chat-microservice';

// @Govind, lastValueFrom makes it wait until the service is done returning the data
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'fse-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  constructor(private dialog: MatDialog, private adminService: AdminService) {}

  async ngOnInit() {
    this.groups = (
      await lastValueFrom(
        this.adminService.adminDataControllerGetProductGroups()
      )
    ).filter((x) => x.group_id !== 1);
    this.updateProductsList();
  }

  groups: ProductGroup[] = [];
  // products: ProductDto[] = [];
  products: Product[];
  totalRecords = 0;
  pageSize = 10;
  pageIndex = 0;
  sortField = '';
  sortOrder: 1 | -1 = 1;
  filters: IFormFilters = {};
  checkedItems: number[] = [];
  checkUncheck(productId: number) {
    if (this.checkedItems.includes(productId)) {
      this.checkedItems = this.checkedItems.filter((x) => x !== productId);
    } else {
      this.checkedItems.push(productId);
    }
  }
  checkAll() {
    if (this.checkedItems.length === this.products.length) {
      this.checkedItems = [];
    } else {
      this.checkedItems = this.products.map((x) => x.productId);
    }
  }
  isChecked(productId: number) {
    return this.checkedItems.includes(productId);
  }
  isAllChecked() {
    return (
      this.products &&
      this.products.length > 0 &&
      this.checkedItems.length === this.products.length
    );
  }
  isSomeChecked(): boolean {
    return (
      this.checkedItems.length > 0 &&
      this.checkedItems.length !== this.products.length
    );
  }
  deleteProduct(productId: number): void {
    const dialogRef = this.dialog.open(DialogConfirmDeleteComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        lastValueFrom(
          this.adminService.adminDataControllerDeleteProduct({
            body: [productId],
          })
        )
          .then(() => {
            this.updateProductsList();
            this.checkedItems = this.checkedItems.filter((x) => x != productId);
          })
          .catch((err) => {
            console.error('deleteProduct:', err);
          });
      }
    });
  }
  async handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateProductsList();
    return event;
  }
  async filterRecords(data: IFormFilters) {
    const { searchText, groupId, status, version } = data;
    this.pageIndex = 0;
    this.filters = { searchText, groupId, status, version };
    this.updateProductsList();
  }
  async addNewProductClick() {
    const dialogRef = this.dialog.open(AddNewProductComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
      maxWidth: '500px',
      maxHeight: '500px',
      height: '100%',
      width: '100%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateProductsList();
      }
    });
  }
  async editProductClick(productId: number) {
    const data = this.products.find((x) => x.productId === productId);
    const dialogRef = this.dialog.open(EditProductComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
      maxWidth: '500px',
      maxHeight: '500px',
      height: '100%',
      width: '100%',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateProductsList();
      }
    });
  }
  async updateProductsList() {
    this.products = await lastValueFrom(
      this.adminService.adminDataControllerGetAllProducts({
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        sortField: this.sortField,
        sortOrder: this.sortOrder === 1 ? 'ASC' : 'DESC',
        searchText: this.filters.searchText,
        status: this.filters.status,
        version: this.filters.version,
        groupId: this.filters.groupId || 0,
      })
    );

    this.totalRecords = +(await lastValueFrom(
      this.adminService.adminDataControllerGetProductsCount()
    ));
  }
  async deleteSelected() {
    if (this.checkedItems.length) {
      lastValueFrom(
        this.adminService.adminDataControllerDeleteProduct({
          body: this.checkedItems,
        })
      )
        .then(() => {
          this.updateProductsList();
          this.checkedItems = [];
        })
        .catch((err) => {
          console.error('deleteSelected:', err);
        });
    }
  }
  async updateProductStatus(status: 'Active' | 'Inactive', id?: number) {
    const IDs = id ? [id] : this.checkedItems;
    lastValueFrom(
      this.adminService.adminDataControllerSetProductStatus({
        body: { IDs: IDs, status: status },
      })
    )
      .then(() => {
        this.updateProductsList();
        this.checkedItems = [];
      })
      .catch((err) => {
        console.error('updateProductStatus:', err);
      });
  }
  async sortProductsList(sortField: string, sortOrder: 1 | -1) {
    if (this.sortField !== sortField) {
      sortOrder = -1;
    }
    this.sortField = sortField;
    this.sortOrder = sortOrder == 1 ? -1 : 1;
    await this.updateProductsList();
  }
}
