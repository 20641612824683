import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularLoggerModule } from '@synopsys-inc/angular-logger';
import { AngularMaterialModule } from '@synopsys-inc/angular-material';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DiagnosticDashboardComponent } from './diagnostic-dashboard/diagnostic-dashboard.component';
import { HeaderComponent } from './header/header.component';
import { HttpRequestInterceptor } from './interceptors/http.interceptor';

// Below modules are generated from services swagger via npm run angular-client-api
import { BffApiModule } from '@snps-webapp/angular-api-client/bff';
import { ChatMicroserviceApiModule } from '@snps-webapp/angular-api-client/chat-microservice';
import { UserManagementApiModule } from '@snps-webapp/angular-api-client/user-management';

import { MsclNgModule } from '@synopsys-inc/angular-ms-common-lib';
import { AppInitService, initializeAppFactory } from './app.init.service';
import { setCsrfHeader } from './csrf/csrf';
import { LogoutComponent } from './logout/logout.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { UserManagementComponent } from './user-management/user-management.component';

import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ChatPaneComponent } from './chat/chat-pane/chat-pane.component';
import { ChatComponent } from './chat/chat/chat.component';
import { ReferencePaneComponent } from './chat/reference-pane/reference-pane.component';
import { ActivityFeedComponent } from './admin/dashboard/activity-feed/activity-feed.component';
import { SummaryCardComponent } from './admin/dashboard/summary-card/summary-card.component';
import { UsersGroupListComponent } from './admin/dashboard/users-group-list/users-group-list.component';
import { ConfirmDeleteSessionComponent } from './chat/confirm-delete-session/confirm-delete-session.component';
import { MatButtonModule } from '@angular/material/button';
import { DataBarChartComponent } from './admin/dashboard/data-bar-chart/data-bar-chart.component';
import { DataDoughnutChartComponent } from './admin/dashboard/data-doughnut-chart/data-doughnut-chart.component';
import { AdminComponent } from './admin/admin.component';
import { AdminSideBarComponent } from './admin/admin-side-bar/admin-side-bar.component';
import { ProductsComponent } from './admin/products/products.component';
import { ProductsCardComponent } from './admin/dashboard/products-card/products-card.component';
import { DialogConfirmDeleteComponent } from './admin/products/dialog-confirm-delete/dialog-confirm-delete.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { UsersComponent } from './admin/users/users.component';
import { ChatsComponent } from './admin/chats/chats.component';
import { ResponseReviewComponent } from './admin/response-review/response-review.component';
import { ConfirmDeleteOrganizationComponent } from './admin/organizations/confirm-delete-organization/confirm-delete-organization.component';
import { ConfirmDeleteUserComponent } from './admin/users/confirm-delete-user/confirm-delete-user.component';
import { AddNewProductComponent } from './admin/products/add-new-product/add-new-product.component';
import { EditProductComponent } from './admin/products/edit-product/edit-product.component';
import { AddNewOrganizationComponent } from './admin/organizations/add-new-organization/add-new-organization.component';
import { EditOrganizationComponent } from './admin/organizations/edit-organization/edit-organization.component';
import { AddNewUserComponent } from './admin/users/add-new-user/add-new-user.component';
import { EditUserComponent } from './admin/users/edit-user/edit-user.component';

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DiagnosticDashboardComponent,
    HeaderComponent,
    SideNavComponent,
    LogoutComponent,
    UserManagementComponent,
    ChatComponent,
    DashboardComponent,
    ReferencePaneComponent,
    ChatPaneComponent,
    ActivityFeedComponent,
    SummaryCardComponent,
    UsersGroupListComponent,
    ConfirmDeleteSessionComponent,
    DataBarChartComponent,
    DataDoughnutChartComponent,
    AdminComponent,
    AdminSideBarComponent,
    ProductsComponent,
    ProductsCardComponent,
    DialogConfirmDeleteComponent,
    OrganizationsComponent,
    UsersComponent,
    ChatsComponent,
    ResponseReviewComponent,
    ConfirmDeleteOrganizationComponent,
    ConfirmDeleteUserComponent,
    AddNewProductComponent,
    EditProductComponent,
    AddNewOrganizationComponent,
    EditOrganizationComponent,
    AddNewUserComponent,
    EditUserComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule,
    AngularMaterialModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    AngularLoggerModule.forRoot({
      level: environment.logger.level,
      enableSourceMaps: environment.logger.enableSourceMaps,
      serverLogging: {
        rootUrl: environment.bffUrl,
        endpointPath: 'log',
        level: environment.logger.level,
        setHeadersCallback: setCsrfHeader,
      },
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    RouterModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatButtonModule,
    ScrollingModule,
    MsclNgModule,
    BffApiModule.forRoot({ rootUrl: environment.bffUrl }),
    ChatMicroserviceApiModule.forRoot({
      rootUrl: environment.servicesUrl + '/chat-service',
    }),
    UserManagementApiModule.forRoot({
      rootUrl: environment.servicesUrl + '/user-management-service',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MatSnackBarRef,
      useValue: {},
    },
    CookieService,
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
