<form #UserDetail="ngForm" class="flex flex-col w-full h-full pb-4" (ngSubmit)="onSubmitForm(UserDetail.value)">
  <h1 mat-dialog-title>Edit User</h1>
  <div mat-dialog-content class="flex-grow flex-shrink !max-h-[unset] overflow-visible">
    <h1>User Detail</h1>

    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input type="text" [ngModel]="currentData.name" name="name" required="*" matInput
        placeholder="Enter User Name Here">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>First Name</mat-label>
      <input type="text" [ngModel]="currentData.firstName" name="firstName" required="*" matInput
        placeholder="Enter First Name">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Last Name</mat-label>
      <input type="text" [ngModel]="currentData.lastName" name="lastName" required="*" matInput
        placeholder="Enter Last Name">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Email-ID</mat-label>
      <input type="email" [ngModel]="currentData.email" name="email" required="*" matInput
        placeholder="ex: abc@example.com">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Phone</mat-label>
      <input type="phone" [ngModel]="currentData.phone" name="phone" required="*" matInput placeholder="+01 124567890">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization</mat-label>
      <mat-select [ngModel]="currentData.organizationId" required="*" name="organizationId">
        <mat-option *ngFor="let organization of organizations" class="MembershipOther"
          [value]="organization.id">{{organization.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>User Status</mat-label>
      <mat-select required="*" name="status" [ngModel]="currentData.status">
        <mat-option value="Active">Active</mat-option>
        <mat-option value="Inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div mat-dialog-actions align="center">
    <button mat-button class="primary-button-bg" [disabled]="!UserDetail.valid">Update</button>
    <button mat-button cdkFocusInitial mat-dialog-close>Cancel</button>
  </div>
</form>