<mat-card class="flex flex-col overflow-hidden w-full px-2 py-2">
  <div class="flex justify-end">
    <button mat-button color="primary" style="background-color:rgb(59, 130, 246,1)" class="bg-blue-500">new
      group</button>
  </div>
  <div class="overflow-auto ">
    <table class="w-[100%]">
      <thead>
        <tr>
          <th class="text-left" *ngFor="let column of displayedColumns">{{column.displayName}}</th>
        </tr>
      </thead>
      <tbody class="overflow-auto">
        <tr *ngFor="let row of dataSource">
          <td class="" *ngFor="let column of displayedColumns">{{row[column.name]}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</mat-card>