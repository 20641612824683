import { Component } from '@angular/core';

@Component({
  selector: 'fse-users-group-list',
  templateUrl: './users-group-list.component.html',
  styleUrls: ['./users-group-list.component.scss'],
})
export class UsersGroupListComponent {
  displayedColumns: IDisplayColumns[] = [
    { name: 'name', displayName: 'Name' },
    { name: 'users', displayName: 'Users' },
    { name: 'permissions', displayName: 'Permissions' },
    { name: 'filterTokens', displayName: 'Filter Tokens' },
  ];
  dataSource = ELEMENT_DATA;
}

export interface IDisplayColumns {
  name: string;
  displayName: string;
}
export interface PeriodicElement {
  name: string;
  users: number;
  permissions: string;
  filterTokens: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { name: 'Super Admin', users: 1, permissions: 'All', filterTokens: '0x' },
  { name: 'Admin', users: 1, permissions: 'All', filterTokens: '0x' },
  { name: 'Normal', users: 1, permissions: 'All', filterTokens: '0x' },
  { name: 'Special', users: 1, permissions: 'All', filterTokens: '0x' },
  { name: 'External', users: 1, permissions: 'All', filterTokens: '0x' },
  { name: 'Internal', users: 1, permissions: 'All', filterTokens: '0x' },
  { name: 'Guest', users: 1, permissions: 'All', filterTokens: '0x' },
];
