/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { EventEmitter, Injectable, Output } from '@angular/core';

import { BffApiService } from '@snps-webapp/angular-api-client/bff';
import { AngularLoggerService } from '@synopsys-inc/angular-logger';
import * as moment from 'moment';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  


  constructor(
    private bffApiService: BffApiService,
    private logger: AngularLoggerService,
    private router: Router
  ) {
    this.screenWidthChange.next(this.screenWidth);
  }

  public sideBarOpened = false;
  acceptedTermsConditions: any;
  screenWidth = 1920;
  screenWidthChange: BehaviorSubject<number> = new BehaviorSubject(0);
  applicationReady: BehaviorSubject<boolean> = new BehaviorSubject(true);
  
  @Output() errorOccuredInAPI = new EventEmitter();

  message: any;

  validToken: BehaviorSubject<any> = new BehaviorSubject<any>({}); // using behaviour subject as the event emitted is not able to reach header after getting the response.

  public environmentDetails = {
    appName: '',
    appUrl: '',
    webSocketUrl: '',
    logoutUrl: '',
  };

  /**
   * To format date value
   * @param date string
   * @param format date format
   * @returns
   */

  formatDate(date: string, format: string) {
    return moment(date).format(format);
  }

  /**
   * To format date value to UTC string
   * @param date string
   * @param format date format
   * @returns
   */
  formatUtcDateString(date: string, format: string) {
    return moment(
      new Date(date).getTime() - new Date(date).getTimezoneOffset() * -60000
    ).format(format);
  }

  /**
   * Function: onChangeSearchIcon
   *
   * Description: Changes the color of the search icon to blue when entered any string in the search field
   * @param stringValue the value of the entered string in the search field
   * @param idValue element id of the mat-search-icon
   * @returns
   */
  onChangeSearchIcon(stringValue: string, idValue: string): any {
    if (stringValue !== '') {
      document?.getElementById(idValue)?.classList?.add('focused-icon');
    } else {
      document?.getElementById(idValue)?.classList?.remove('focused-icon');
    }
  }

  setMessage(data: any) {
    this.message = data;
  }

  getMessage() {
    return this.message;
  }

  /**
   * Function: getFormatedNumber
   *
   * Description: To get the number value formatted to 2 places after the decimal
   * and if it is 0 after the decimals then completely remove the decimals
   * @param val value to be formatted
   * @returns val in integer if after decimal place only .00 is there and val upto 2 decimal if after decimal places there is value
   */
  getFormatedNumber(val) {
    if (Number.isInteger(val)) {
      return val;
    } else {
      return Number(val?.toFixed(2));
    }
  }


  /*
      Function : validateHTMLTags,
      Description: To validate the < & > for the input value
      Params: value: string,
      Return Type:boolean ,
  */
  validateHTMLTags(control: any) {
    if (control && control.value && control.value.length > 0) {
      const value = control.value;
      const valid = value.includes('<') || value.includes('>');

      if (valid) {
        // control.setErrors({...control.errors, invalid: true})
        return {
          invalid: true,
        };
      }
    }
    return null;
  }

  /**
   * Function: compare()
   *
   * compare the elements of the arrays in ascending order
   * @param a first element to compare
   * @param b second element to compare
   * @param isAsc type: boolean value
   * @returns sorted arrays elements
   */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  handleNullCheck(value, updatedValue) {
    return value === '' || value === null || value === undefined
      ? updatedValue
      : value;
  }




  /**
   * Function: logout
   * Decription: Method to handle the logout
   * @param - none
   * @returns - void
   */
  async logout() {

    localStorage.clear(); // Clear the local storage
    sessionStorage.clear(); // clear the session storage

    try {
      await lastValueFrom(this.bffApiService.authControllerLogout())
    }
    catch (err: any) {
      this.logger.error(
        `From BffApiService the authControllerLogout is returning error ${err.message}`
      );

    }
    ;
    // }

  }



  /**
   * Function: isStringFormatResponse
   *
   * Description: To check if the response coming from the API is in string format or in object format
   * @param response API response
   * @returns response in object format
   */
  isStringFormatResponse(response: any): any {
    return typeof response === 'string'
      ? JSON.parse(response.toString())
      : response;
  }

  /**
   * Function: getCurrentScreenUrl
   *
   * Description: To get the url of the current screens
   * @param
   * @returns url of the current screen
   */
  getCurrentScreenUrl() {
    return this.router.url.toLowerCase();
  }
}