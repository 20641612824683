<mat-sidenav-container hasBackdrop="true" class="!absolute !z-50  !h-full !bg-white/5"
  [ngClass]="opened?'!w-full':'!w-auto'">
  <mat-sidenav class="sidenav sm:!top-[50px]" mode="over" [fixedInViewport]="true" [fixedTopGap]="60" [opened]="opened"
    (openedChange)="closeSideBar($event)">
    <mat-list class="cursor-pointer [&>a:hover]:bg-gray-200 [&>*_a:hover]:bg-gray-200">
      <div mat-list-item class="flex justify-between px-4 py-2" (click)="toggle[0] = !toggle[0]">
        <span matLine>Admin</span>
        <span>
          <mat-icon *ngIf="!toggle[0]">expand_more</mat-icon>
          <mat-icon *ngIf="toggle[0]">expand_less</mat-icon>
        </span>
      </div>

      <div [hidden]="!toggle[0]" class="pl-2">
        <a mat-list-item style="cursor:pointer" [routerLink]="['/admin/dashboard']" (click)="toggleSideBar()"
          [ngClass]="{'bg-gray-200': currentUrl === '/admin/dashboard'}"
          routerLinkActive="router-link-active">Dashboard</a>
        <a mat-list-item style="cursor:pointer" [routerLink]="['/admin/products']" (click)="toggleSideBar()"
          [ngClass]="{'bg-gray-200': currentUrl === '/admin/products'}"
          routerLinkActive="router-link-active">Products</a>
        <a mat-list-item style="cursor:pointer" [routerLink]="['/admin/organizations']" (click)="toggleSideBar()"
          [ngClass]="{'bg-gray-200': currentUrl === '/admin/organizations'}"
          routerLinkActive="router-link-active">Organizations</a>
        <a mat-list-item style="cursor:pointer" [routerLink]="['/admin/users']" (click)="toggleSideBar()"
          [ngClass]="{'bg-gray-200': currentUrl === '/admin/users'}" routerLinkActive="router-link-active">Users</a>
        <a mat-list-item style="cursor:pointer" [routerLink]="['/admin/chats']" (click)="toggleSideBar()"
          [ngClass]="{'bg-gray-200': currentUrl === '/admin/chats'}" routerLinkActive="router-link-active">Chats</a>
        <a mat-list-item style="cursor:pointer" [routerLink]="['/admin/response-review']" (click)="toggleSideBar()"
          [ngClass]="{'bg-gray-200': currentUrl === '/admin/response-review'}"
          routerLinkActive="router-link-active">Response Review</a>
      </div>
      <a mat-list-item style="cursor:pointer" [routerLink]="['/chat']" (click)="toggleSideBar()"
        [ngClass]="{'bg-gray-200': currentUrl === '/chat'}" routerLinkActive="router-link-active">Chat</a>
      <a mat-list-item style="cursor:pointer" [routerLink]="['/diagnostic']" (click)="toggleSideBar()"
        [ngClass]="{'bg-gray-200': currentUrl === '/diagnostic'}" routerLinkActive="router-link-active">Diagnostic</a>
      <a mat-list-item style="cursor:pointer" [routerLink]="['/user-management']" (click)="toggleSideBar()"
        [ngClass]="{'bg-gray-200': currentUrl === '/user-management'}" routerLinkActive="router-link-active">User
        Management</a>
      <a mat-list-item style="cursor:pointer" [routerLink]="['/logout']" (click)="toggleSideBar()"
        [ngClass]="{'bg-gray-200': currentUrl === '/logout'}" routerLinkActive="router-link-active">Logout</a>
    </mat-list>
  </mat-sidenav>
</mat-sidenav-container>
