<div class="flex flex-col px-4 py-4 w-full h-full">
  <div>
    <h1 class="text-3xl">Response Review Management</h1>
  </div>
  <div>
    <form class="flex items-center  sm:flex-wrap sm:[&>*]:w-full " #formFilters="ngForm"
      (ngSubmit)="filterRecords(formFilters.value)">
      <mat-form-field class="">
        <mat-label>Search User</mat-label>
        <input type="search" ngModel name="searchText" matInput placeholder="Ex. Synopsys" value="">
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Organization</mat-label>
        <mat-select ngModel name="organizationId">
          <mat-option>-- ALL --</mat-option>
          <mat-option *ngFor="let organization of organizations"
            [value]="organization.id">{{organization.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Rating</mat-label>
        <mat-select ngModel name="rating">
          <mat-option>-- ALL --</mat-option>
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="4">4</mat-option>
          <mat-option [value]="5">5</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button color="primary" class="primary-button-bg mx-2">
        <mat-icon>search</mat-icon>
        Search
      </button>
    </form>
  </div>
  <div class="w-full my-2 overflow-y-visible sm:flex-grow overflow-x-auto sm:overflow-y-hidden">
    <table class="table-auto w-full text-left my-2 min-w-[800px]  [&>*_td]:pb-1">
      <thead>
        <tr class=" border-b-2 border-b-slate-200">
          <th class="w-[50px]">
            <mat-checkbox [indeterminate]="isSomeChecked()" [checked]="isAllChecked()"
              (change)="checkAll()"></mat-checkbox>
          </th>
          <th class="">
            User Name
          </th>
          <th>
            Question
          </th>
          <th>
            Answer
          </th>
          <th>
            Rating
          </th>
          <th>
            FeedBack
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let review of userReview" class="hover:bg-slate-200">
          <td>
            <mat-checkbox [checked]="isChecked(review.reviewId)"
              (change)="checkUncheck(review.reviewId)"></mat-checkbox>
          </td>
          <td class="position--relative">
            <span>{{review.user.name}}</span><br />
            <small class="absolute mt-[-5px] text-xs font-thin ">{{review.user.email}}</small>
          </td>
          <td>
            {{review.question}}
          </td>
          <td>
            {{review.answer}}
          </td>
          <td>
            {{review.rating}}
          </td>
          <td>
            {{review.feedback}}
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <div class="flex flex-col flex-grow flex-shrink">
    <mat-divider></mat-divider>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"
      style="background-color:unset;">
    </mat-paginator>
  </div>
</div>