<div class="flex flex-col px-4 py-4 w-full h-full relative">
  <div>
    <h1 class="text-3xl">Organization Management</h1>
  </div>
  <div>
    <form class="flex items-center  sm:flex-wrap sm:[&>*]:w-full " #formFilters="ngForm"
      (ngSubmit)="filterRecords(formFilters.value)">
      <mat-form-field class="">
        <mat-label>Search Organization</mat-label>
        <input type="search" ngModel name="searchText" matInput placeholder="Ex. Cyclotron" value="">
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Membership</mat-label>
        <mat-select #ids multiple ngModel name="membershipIds">
          <mat-option #allS [value]="-1" (click)="ids.value=allS.selected?[-1]:[]">-- ALL --</mat-option>
          <mat-option *ngFor="let membership of memberships" class="MembershipOther"
            (click)="ids.value=ids.value[0]===-1?ids.value.slice(1):ids.value"
            [value]="membership.id">{{membership.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Status</mat-label>
        <mat-select ngModel name="status">
          <mat-option>-- ALL --</mat-option>
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Deactive">Deactive</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button color="primary" class="primary-button-bg mx-2">
        <mat-icon>search</mat-icon>
        Search
      </button>
    </form>
  </div>
  <div class="w-full my-2 overflow-y-visible sm:flex-grow overflow-x-auto sm:overflow-y-hidden sm:mt-12">
    <table class="table-auto w-full text-left my-2 min-w-[800px]  [&>*_td]:pb-1">
      <thead>
        <tr class=" border-b-2 border-b-slate-200">
          <th class="w-[50px]">
            <mat-checkbox [indeterminate]="isSomeChecked()" [checked]="isAllChecked()"
              (change)="checkAll()"></mat-checkbox>
          </th>
          <th class="">
            <div class="flex justify-between items-center"><span>Organization Name</span>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="deleteSelected()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Selected</span>
                  </button>
                  <button mat-menu-item [disabled]="checkedItems.length===0"
                    (click)="updateOrganizationsStatus('Active')">
                    <mat-icon>done_all</mat-icon>
                    <span>Activate Selected</span>
                  </button>
                  <button mat-menu-item [disabled]="checkedItems.length===0"
                    (click)="updateOrganizationsStatus('Inactive')">
                    <mat-icon>remove_done</mat-icon>
                    <span>Inactive Selected</span>
                  </button>
                </mat-menu>
              </div>
            </div>

          </th>

          <th>
            Users
          </th>
          <th>
            Membership
          </th>

          <th>
            Status
          </th>
          <th class="w-[300px]">
            <div class="flex justify-between items-center pl-4">
              <span>Action</span>
              <button mat-button matTooltip="Add New Organization" class="sm:!absolute sm:right-2 sm:mb-20"
                (click)="addNewOrganizationClick()"><mat-icon>add</mat-icon>Add New Organization</button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="font-medium">
        <tr *ngFor="let organization of organizations" class="hover:bg-slate-200">
          <td>
            <mat-checkbox [checked]="isChecked(organization.id)"
              (change)="checkUncheck(organization.id)"></mat-checkbox>
          </td>
          <td class="position--relative">
            <span>{{organization.name}}</span><br />
            <small class="absolute mt-[-5px] text-xs font-thin ">{{organization.email}}</small>
          </td>
          <td>
            {{organization.users}}
          </td>
          <td class="position--relative">
            <span>{{organization.membershipType}}</span><br />
            <small class="absolute mt-[-5px] text-xs font-thin ">{{organization.membershipExpire}}</small>
          </td>
          <td>
            {{organization.status}}
          </td>
          <td>
            <button mat-button matTooltip="Edit Organization"
              (click)="editOrganizationClick(organization.id)"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button matTooltip="Delete Organization"
              (click)="deleteOrganization(organization.id)"><mat-icon>delete</mat-icon>Delete</button>
            <button mat-button matTooltip="Activate Organization"
              (click)="updateOrganizationsStatus('Active',organization.id)"
              *ngIf="organization.status!=='Active'"><mat-icon>done_all</mat-icon>Activate</button>
            <button mat-button matTooltip="Deactivate Organization"
              (click)="updateOrganizationsStatus('Inactive',organization.id)"
              *ngIf="organization.status==='Active'"><mat-icon>remove_done</mat-icon>Deactivate</button>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <div class="flex flex-col flex-grow flex-shrink">
    <mat-divider></mat-divider>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"
      style="background-color:unset;">
    </mat-paginator>
  </div>
</div>