import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../../../services/admin/admin.service';
import { OrganizationDto, UserDto } from '../../../services/admin/admin_dto';

@Component({
  selector: 'fse-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddNewUserComponent>,
    public adminService: AdminService
  ) {}
  async ngOnInit() {
    this.organizations = await this.adminService.getOrganizations({
      pageSize: 0,
    });
  }

  organizations: OrganizationDto[] = [];

  onSubmitForm(formData: UserDto) {
    console.log(formData);
    this.adminService.addNewUser(formData).then((data) => {
      this.dialogRef.close(data);
    });
  }
}
