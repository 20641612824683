/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HealthResponseDto } from '../models/health-response-dto';
import { LevelDto } from '../models/level-dto';
import { LogEntry } from '../models/log-entry';
import { VersionResponseDto } from '../models/version-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ChatMicroserviceApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation loggerControllerGetLevel
   */
  static readonly LoggerControllerGetLevelPath = '/logging/level';

  /**
   * Get logger enabled level for verbose, debug, log, error, warn
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerGetLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLevel$Response(params: {
    level: any;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.LoggerControllerGetLevelPath, 'get');
    if (params) {
      rb.query('level', params.level, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Get logger enabled level for verbose, debug, log, error, warn
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerGetLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLevel(params: {
    level: any;
  }): Observable<boolean> {

    return this.loggerControllerGetLevel$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation loggerControllerSetLevelFromRest
   */
  static readonly LoggerControllerSetLevelFromRestPath = '/logging/level';

  /**
   * Set log level to verbose, debug, log, error, warn
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerSetLevelFromRest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loggerControllerSetLevelFromRest$Response(params: {
    body: LevelDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.LoggerControllerSetLevelFromRestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Set log level to verbose, debug, log, error, warn
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerSetLevelFromRest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loggerControllerSetLevelFromRest(params: {
    body: LevelDto
  }): Observable<boolean> {

    return this.loggerControllerSetLevelFromRest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation loggerControllerGetLog
   */
  static readonly LoggerControllerGetLogPath = '/logging/log';

  /**
   * Get last log entries from FIFO
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerGetLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLog$Response(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.LoggerControllerGetLogPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageOffset', params.pageOffset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'results'?: Array<LogEntry>;
        'info'?: {
        'pageSize'?: number;
        'pageOffset'?: number;
        'recordSkip'?: number;
        'recordTotal'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Get last log entries from FIFO
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerGetLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLog(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}> {

    return this.loggerControllerGetLog$Response(params).pipe(
      map((r: StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>) => r.body as {
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
})
    );
  }

  /**
   * Path part for operation loggerControllerGetError
   */
  static readonly LoggerControllerGetErrorPath = '/logging/error';

  /**
   * Get last error log entries from FIFO
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerGetError()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetError$Response(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.LoggerControllerGetErrorPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageOffset', params.pageOffset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'results'?: Array<LogEntry>;
        'info'?: {
        'pageSize'?: number;
        'pageOffset'?: number;
        'recordSkip'?: number;
        'recordTotal'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Get last error log entries from FIFO
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerGetError$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetError(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}> {

    return this.loggerControllerGetError$Response(params).pipe(
      map((r: StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>) => r.body as {
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
})
    );
  }

  /**
   * Path part for operation versionControllerCheck
   */
  static readonly VersionControllerCheckPath = '/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerCheck$Response(params?: {
  }): Observable<StrictHttpResponse<VersionResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.VersionControllerCheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VersionResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `versionControllerCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerCheck(params?: {
  }): Observable<VersionResponseDto> {

    return this.versionControllerCheck$Response(params).pipe(
      map((r: StrictHttpResponse<VersionResponseDto>) => r.body as VersionResponseDto)
    );
  }

  /**
   * Path part for operation healthControllerCheck
   */
  static readonly HealthControllerCheckPath = '/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthControllerCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthControllerCheck$Response(params?: {
  }): Observable<StrictHttpResponse<HealthResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.HealthControllerCheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HealthResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthControllerCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthControllerCheck(params?: {
  }): Observable<HealthResponseDto> {

    return this.healthControllerCheck$Response(params).pipe(
      map((r: StrictHttpResponse<HealthResponseDto>) => r.body as HealthResponseDto)
    );
  }

  /**
   * Path part for operation metricsControllerIndex
   */
  static readonly MetricsControllerIndexPath = '/metrics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `metricsControllerIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerIndex$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.MetricsControllerIndexPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `metricsControllerIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerIndex(params?: {
  }): Observable<void> {

    return this.metricsControllerIndex$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation metricsControllerMetricsApiKet
   */
  static readonly MetricsControllerMetricsApiKetPath = '/metrics/with-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `metricsControllerMetricsApiKet()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerMetricsApiKet$Response(params: {
    'api-key': any;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ChatMicroserviceApiService.MetricsControllerMetricsApiKetPath, 'get');
    if (params) {
      rb.query('api-key', params['api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `metricsControllerMetricsApiKet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerMetricsApiKet(params: {
    'api-key': any;
  }): Observable<void> {

    return this.metricsControllerMetricsApiKet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
