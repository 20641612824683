import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AdminService,
  Product,
  ProductDto,
  ProductGroup,
} from '@snps-webapp/angular-api-client/chat-microservice';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'fse-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditProductComponent>,
    public adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public currentProduct: Product
  ) {}
  async ngOnInit() {
    this.groups = await lastValueFrom(
      this.adminService.adminDataControllerGetProductGroups()
    );
  }
  groups: ProductGroup[] = [];

  onSubmitForm(formData: ProductDto) {
    console.log(formData);
    lastValueFrom(
      this.adminService.adminDataControllerUpdateProduct({
        id: this.currentProduct.productId,
        body: formData,
      })
    )
      .then((data) => {
        console.log('onSubmitForm:', data);
        this.dialogRef.close(data);
      })
      .catch((err) => {
        console.error('onSubmitForm:', err);
      });
  }
}
