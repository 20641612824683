import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../services/admin/admin.service';
import {
  IFormFilters,
  OrganizationDto,
  UserChatsDto,
} from '../../services/admin/admin_dto';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'fse-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit {
  constructor(public dialog: MatDialog, public adminService: AdminService) {}
  async ngOnInit() {
    this.organizations = await this.adminService.getOrganizations({
      status: 'Active',
      pageSize: 0,
    });

    await this.updateChatsList();
  }

  organizations: OrganizationDto[] = [];
  usersChats: UserChatsDto[] = [];
  totalRecords = 0;
  pageSize = 10;
  pageIndex = 0;
  filters: IFormFilters = {};
  checkedItems: string[] = [];
  checkUncheck(id: string) {
    if (this.checkedItems.includes(id)) {
      this.checkedItems = this.checkedItems.filter((x) => x !== id);
    } else {
      this.checkedItems.push(id);
    }
  }
  checkAll() {
    if (this.checkedItems.length === this.usersChats.length) {
      this.checkedItems = [];
    } else {
      this.checkedItems = this.usersChats.map((x) => x.user.id);
    }
  }

  isChecked(id: string) {
    return this.checkedItems.includes(id);
  }
  isAllChecked() {
    return (
      this.usersChats.length > 0 &&
      this.checkedItems.length === this.usersChats.length
    );
  }
  isSomeChecked(): boolean {
    return (
      this.checkedItems.length > 0 &&
      this.checkedItems.length !== this.usersChats.length
    );
  }
  async handlePageEvent(event: PageEvent) {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.updateChatsList();
  }
  async updateChatsList() {
    this.usersChats = await this.adminService.getUsersChat(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      },
      this.filters
    );
    this.totalRecords = await this.adminService.getTotalUsersChats(
      this.filters
    );
  }
  async filterRecords(data: IFormFilters) {
    const { searchText, status, organizationId } = data;
    this.pageIndex = 0;
    this.filters = { searchText, status, organizationId };
    console.log('Filter:', this.filters);
    this.updateChatsList();
  }
}
