/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Message } from '@snps-webapp/api-interfaces';

import { BffApiService } from '@snps-webapp/angular-api-client/bff';
import { AngularLoggerService } from '@synopsys-inc/angular-logger';
import { SharedService } from './shared/services/shared.service';

import {
  ErrorMessage,
  LogMessages,
  MessageCategory,
  MessageDescription,
  MessageMetaData,
} from '@synopsys-inc/ms-common-lib/dist/log-messages-fabric';
import { HttpService } from './services/http.service';

import { SocketsService } from './services/socket.service';
import { last, lastValueFrom } from 'rxjs';

enum eApplicationsErrorCategory {
  SERVICE_AVAILABILITY_ERROR = 'SERVICE_AVAILABILITY_ERROR',
}
@LogMessages
export class GeneralApplicationErrors {
  @ErrorMessage('Service ${0,<service name>} is not responding')
  @MessageMetaData({ audit: 'devops' })
  @MessageCategory(eApplicationsErrorCategory.SERVICE_AVAILABILITY_ERROR)
  @MessageDescription(
    'The peer service seem to be offline or cannot be reached for other reasons'
  )
  static GAPPE000001_PeerServiceNotResponding;
}

@Component({
  selector: 'fse-snps-webapp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    public sharedService: SharedService,
    private bffApiService: BffApiService,
    private logger: AngularLoggerService,
    public socketService: SocketsService,
    public dialogRef: MatDialogRef<any>
  ) {
    if (!window?.location?.pathname?.includes('/error-page')) {
      this.sharedService.applicationReady.next(true);

      //   this.getPermissionControlDetails();
      this.isError = false;
    } else {
      this.allowComponentLoad = true;
      this.isError = true;
    }
    router.events.subscribe((event: any) => {
      this.routeChangeEvent(event);
    });
  }

  allowComponentLoad = false;
  userInfo: any;
  showHeader = true;
  isError = false;
  softShutdownEnforced: boolean;

  routeChangeEvent(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      if (event.url.toLowerCase().includes('logout')) {
        this.showHeader = false;
        this.sharedService.logout();
        return;
      }
    }
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      // or here
    }
  }

  /**
   * Method to toggle sidebar
   */

  toggleSidebar(event) {
    this.sharedService.sideBarOpened = event;
  }

  /**
   * Method to trigger on every navigation
   */
  changeOfRoutes() {
    // console.log('route change');
  }

  ngOnInit(): void {
    this.sharedService.screenWidth = window.innerWidth;
    this.sharedService.screenWidthChange.next(this.sharedService.screenWidth);
    this.onWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.sharedService.screenWidth = window.innerWidth;
    sessionStorage.setItem(
      'screenWidth',
      String(this.sharedService.screenWidth)
    );
    this.sharedService.screenWidthChange.next(this.sharedService.screenWidth);
  }
}
