<div class="flex flex-col px-4 py-4 w-full h-full">
  <div>
    <h1 class="text-3xl">Users Chat Management</h1>
  </div>
  <div>
    <form class="flex items-center  sm:flex-wrap sm:[&>*]:w-full " #formFilters="ngForm"
      (ngSubmit)="filterRecords(formFilters.value)">
      <mat-form-field class="">
        <mat-label>Search User</mat-label>
        <input type="search" ngModel name="searchText" matInput placeholder="Ex. UserName or Email" value="">
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Organization</mat-label>
        <mat-select ngModel name="organizationId">
          <mat-option>-- ALL --</mat-option>
          <mat-option *ngFor="let organization of organizations"
            [value]="organization.id">{{organization.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Status</mat-label>
        <mat-select ngModel name="status">
          <mat-option>-- ALL --</mat-option>
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Inactive">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button color="primary" class="primary-button-bg mx-2">
        <mat-icon>search</mat-icon>
        Search
      </button>
    </form>
  </div>
  <div class="w-full my-2 overflow-y-visible sm:flex-grow overflow-x-auto sm:overflow-y-hidden">
    <table class="table-auto w-full text-left my-2 min-w-[800px]">
      <thead>
        <tr class=" border-b-2 border-b-slate-200">
          <th class="w-[50px]">
            <mat-checkbox [indeterminate]="isSomeChecked()" [checked]="isAllChecked()"
              (change)="checkAll()"></mat-checkbox>
          </th>
          <th class="">
            User Name
          </th>
          <th>
            Chat Sessions
          </th>
          <th>
            Chat Messages
          </th>

        </tr>
      </thead>
      <tbody class="font-medium">
        <tr *ngFor="let userChats of usersChats" class="hover:bg-slate-200 border border-slate-400">
          <td>
            <mat-checkbox [checked]="isChecked(userChats.user.id)"
              (change)="checkUncheck(userChats.user.id)"></mat-checkbox>
          </td>
          <td class="position--relative">
            <span>{{userChats.user.name}}</span><br />
            <small class="absolute mt-[-5px] text-xs font-thin ">{{userChats.user.email}}</small>
          </td>
          <td class="py-2">
            <ul class="pl-5 list-disc">
              <li *ngFor="let chatSession of userChats.chatSessions">{{chatSession.session_title}}</li>
            </ul>
          </td>
          <td>

          </td>

        </tr>
      </tbody>
    </table>

  </div>
  <div class="flex flex-col flex-grow flex-shrink">
    <mat-divider></mat-divider>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"
      style="background-color:unset;">
    </mat-paginator>
  </div>
</div>