import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ChatMessage } from '@snps-webapp/angular-api-client/chat-microservice';

@Component({
    selector: 'fse-chat-pane',
    templateUrl: './chat-pane.component.html',
    styleUrls: ['./chat-pane.component.scss'],
})
export class ChatPaneComponent implements AfterViewChecked {
    @ViewChild('chatWindow') private chatWindow: ElementRef;
    @Input() messages: ChatMessage[] = [];
    @Input() sessionID = -1;
    @Output() prompt: EventEmitter<ChatMessage> = new EventEmitter<ChatMessage>();

    public currentPrompt: ChatMessage = {
        message_id: 1,
        context: '',
        sent_at: '',
        sent_by: 0,
        session_id: this.sessionID,
    };

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    private scrollToBottom(): void {
        if (this.isUserAtBottom()) {
            this.chatWindow.nativeElement.scrollTop =
                this.chatWindow.nativeElement.scrollHeight;
        }
    }

    private isUserAtBottom(): boolean {
        // const element = this.chatWindow.nativeElement;
        // const threshold = 150;
        // console.log({
        //   element,
        //   threshold,
        //   scrollTop: element.scrollTop,
        //   scrollHeight: element.scrollHeight,
        //   clientHeight: element.clientHeight,
        //   calculatedValue:
        //     element.scrollHeight - element.scrollTop - element.clientHeight,
        // });
        // return (
        //   element.scrollHeight - element.scrollTop - element.clientHeight <
        //   threshold
        // );
        return true;
    }

    sendPrompt() {
        if (this.currentPrompt.context.trim() === '') {
            return;
        }

        this.currentPrompt.sent_at = new Date().toUTCString();

        this.currentPrompt.session_id = this.sessionID;
        this.prompt.emit({ ...this.currentPrompt });

        this.currentPrompt = {
            session_id: this.currentPrompt.session_id,
            message_id: 0,
            context: '',
            sent_at: '',
            sent_by: 0,
        };
    }
}
