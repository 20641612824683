<mat-card class="w-full h-full p-4 flex flex-col space-between">
  <div>
    <mat-card-header>
      <mat-card-title>Reference</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
    <div>Your references will appear here.</div>  
    </mat-card-content>
  </div>
</mat-card>
