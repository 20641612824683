<form #productDetail="ngForm" class="flex flex-col w-full h-full pb-4" (ngSubmit)="onSubmitForm(productDetail.value)">
  <h1 mat-dialog-title>Add New Product</h1>
  <div mat-dialog-content class="flex-grow flex-shrink">
    <h1>Product Detail</h1>

    <mat-form-field class="w-full">
      <mat-label>Product Name</mat-label>
      <input type="text" ngModel name="name" required="*" matInput placeholder="Enter Product Name Here" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Product Version</mat-label>
      <input type="text" ngModel name="version" required="*" matInput placeholder="Ex. 1.0" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Product Status</mat-label>
      <mat-select required="*" name="status" [ngModel]="default.value">
        <mat-option value="Active" #default selected>Active</mat-option>
        <mat-option value="Inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Product Group</mat-label>
      <mat-select required="*" ngModel name="groupId">
        <mat-option *ngFor="let group of groups" [value]="group.group_id">{{group.group}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-button class="primary-button-bg" [disabled]="!productDetail.valid">Save</button>
    <button mat-button cdkFocusInitial mat-dialog-close>Cancel</button>
  </div>
</form>