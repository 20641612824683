import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteUserComponent } from './confirm-delete-user/confirm-delete-user.component';
import { AdminService } from '../../services/admin/admin.service';
import {
  IFormFilters,
  OrganizationDto,
  UserDto,
} from '../../services/admin/admin_dto';
import { PageEvent } from '@angular/material/paginator';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';

@Component({
  selector: 'fse-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  constructor(public dialog: MatDialog, public adminService: AdminService) {}
  async ngOnInit() {
    this.organizations = await this.adminService.getOrganizations({
      status: 'Active',
    });
    await this.updateUsersList();
  }
  organizations: OrganizationDto[] = [];
  users: UserDto[] = [];
  totalRecords = 0;
  pageSize = 10;
  pageIndex = 0;
  filters: IFormFilters = {};
  checkedItems: string[] = [];
  checkUncheck(id: string) {
    if (this.checkedItems.includes(id)) {
      this.checkedItems = this.checkedItems.filter((x) => x !== id);
    } else {
      this.checkedItems.push(id);
    }
  }
  checkAll() {
    if (this.checkedItems.length === this.users.length) {
      this.checkedItems = [];
    } else {
      this.checkedItems = this.users.map((x) => x.id);
    }
  }

  isChecked(id: string) {
    return this.checkedItems.includes(id);
  }
  isAllChecked() {
    return (
      this.users.length > 0 && this.checkedItems.length === this.users.length
    );
  }
  isSomeChecked(): boolean {
    return (
      this.checkedItems.length > 0 &&
      this.checkedItems.length !== this.users.length
    );
  }
  deleteUser(id: string): void {
    const dialogRef = this.dialog.open(ConfirmDeleteUserComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.adminService.deleteUsers([id]).then((result) => {
          if (result) {
            this.updateUsersList();
            this.checkedItems = this.checkedItems.filter((x) => x != id);
          }
        });
      }
    });
  }
  async handlePageEvent(event: PageEvent) {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.updateUsersList();
  }
  async updateUsersList() {
    this.users = await this.adminService.getUsers(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      },
      this.filters
    );
    this.totalRecords = await this.adminService.getTotalUsers(this.filters);
  }
  async filterRecords(data: IFormFilters) {
    const { searchText, status, organizationId } = data;
    this.pageIndex = 0;
    this.filters = { searchText, status, organizationId };
    console.log('Filter:', this.filters);
    this.updateUsersList();
  }
  async addNewUserClick() {
    const dialogRef = this.dialog.open(AddNewUserComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
      maxWidth: '500px',
      maxHeight: '800px',
      height: '100%',
      width: '100%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log({ result });
        this.updateUsersList();
      }
    });
  }
  async editUserClick(id: string) {
    const data = this.users.find((x) => x.id === id);
    const dialogRef = this.dialog.open(EditUserComponent, {
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms',
      maxWidth: '500px',
      maxHeight: '800px',
      height: '100%',
      width: '100%',
      data: { ...data },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateUsersList();
      }
    });
  }
  async deleteSelected() {
    if (this.checkedItems.length) {
      this.adminService.deleteUsers(this.checkedItems).then((result) => {
        if (result) {
          this.updateUsersList();
          this.checkedItems = [];
        }
      });
    }
  }
  async updateUsersStatus(status: 'Active' | 'Inactive', id?: string) {
    const ids = id ? [id] : this.checkedItems;
    if (ids.length) {
      this.adminService.setUsersStatus(ids, status).then(() => {
        this.updateUsersList();
      });
    }
  }
}
