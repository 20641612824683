import { Component, OnInit } from '@angular/core';
import {
  AddMessageDto,
  AddSessionDto,
  ChatMessage,
  ChatService,
  ChatSession,
} from '@snps-webapp/angular-api-client/chat-microservice';
import { SocketsService } from '../../services/socket.service';

import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteSessionComponent } from '../confirm-delete-session/confirm-delete-session.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'fse-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  public messages: ChatMessage[] = [];
  public sessions: ChatSession[] = [];
  public sessionID = 0;
  public chatSession: ChatSession;
  public sessionIdToBeDeleted = 0;
  public toggleChatSession = false;

  private userEmail = '';
  private loginId = 0;

  public sentencesFromBot = [];

  constructor(
    private socketService: SocketsService,
    private route: ActivatedRoute,
    private router: Router,
    private chatService: ChatService,
    public dialog: MatDialog
  ) {
    console.log('===================constructor called===================');
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  async ngOnInit() {
    const session = JSON.parse(sessionStorage.getItem('sessionLogin'));
    this.userEmail = session.profile.email;
    this.loginId = session.login.loginId;

    console.log('===================ngOnInit===================');
    await this.loadMessageHistory();
    await this.subscribeForProcessRouteParameter();

    this.socketService.messageSubject.subscribe((msg) => {
      if (this.messages[this.messages.length - 1].context === 'Thinking...')
        this.messages[this.messages.length - 1].context = '';
      this.messages[this.messages.length - 1].context +=
        msg.data.context.replace(/\n/g, '<br/>');
    });
  }

  async loadMessageHistory() {
    // npm run angular-client-api
    // todo: unsubscribe
    this.chatService
      .chatControllerFindSessionsForUserEmail({ user_email: this.userEmail })
      .subscribe((result) => {
        console.log('ChatService.chatControllerFindSessionsForLogin:', result);
        this.sessions = result;
        const param_session_id = +this.route.snapshot.params['sessionId'];
        console.log({ param_session_id });
        this.setCurrentChatSession(param_session_id || 0);
      });
  }

  async loadChatMessages(sessionID: string) {
    // npm run angular-client-api
    // todo: unsubscribe
    this.chatService
      .chatControllerFindSession({ sessionId: sessionID || '' })
      .subscribe((result) => {
        console.log('CHAT-MESSAGES', result);
        this.messages = result.sort((a, b) => a.message_id - b.message_id);
        this.messages.forEach((item) => {
          item.context = item.context.replace(/\n/g, '<br/>');
        });
      });
  }

  async subscribeForProcessRouteParameter() {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        const param_session_id = +val.state.root.firstChild.params['sessionId'];
        this.setCurrentChatSession(param_session_id);
      }
    });
  }

  setCurrentChatSession(sessionId: number) {
    if (sessionId) {
      this.chatSession = this.sessions.find((x) => x.session_id === sessionId);
      if (this.chatSession) {
        if (this.sessionID !== sessionId) {
          this.sessionID = sessionId;
          this.loadChatMessages(sessionId.toString());
        }
      }
    }
  }

  async sendPrompt($event: ChatMessage) {
    const prompt = { ...$event };

    //create a session with a title from the LLM if there isn't one already
    if (!this.sessionID) {
      const addSessionDto: AddSessionDto = {
        sessionTitle: prompt.context,
        loginId: this.loginId,
      };

      const session = await lastValueFrom(
        this.chatService.chatControllerCreateSession({ body: addSessionDto })
      );
      this.sessionID = session.session_id;

      this.sessions.unshift(session);
      this.chatSession = session;
    }

    // show the users message in the chat window
    this.messages.push(prompt);

    //add another message for the bot to response content
    this.messages.push({
      message_id: 0,
      context: 'Thinking...',
      sent_at: '',
      sent_by: 1,
      session_id: this.sessionID,
    });

    console.log('==========sendPrompt========', prompt);
    const addMessageDto: AddMessageDto = {
      context: prompt.context,
      sentBy: 0,
      sessionId: this.sessionID,
    };

    await this.createMessage(addMessageDto);
  }

  async createMessage(addMessageDto: AddMessageDto) {
    console.log('REQUEST:', addMessageDto);

    await this.chatService
      .chatControllerCreateMessage({ body: addMessageDto })
      .subscribe();
  }

  async startNewChat() {
    console.log('CLEAR CHAT SESSION: ', {
      sessionID: this.sessionID,
      messages: this.messages,
    });
    this.sessionID = 0;
    this.messages = [];
    this.chatSession = undefined;
    console.log('CREATE NEW CHAT SESSION: ', {
      sessionID: this.sessionID,
      messages: this.messages,
    });
    this.router.navigate(['/chat']);
  }

  deleteChatHistory(sessionID: number) {
    console.log('Deleting chat of session:', sessionID);
    this.sessionIdToBeDeleted = sessionID;
    this.openDialog('300ms', '300ms');
  }

  openDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    const dialogRef = this.dialog.open(ConfirmDeleteSessionComponent, {
      // width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Session ID Deleted =', this.sessionIdToBeDeleted);
        this.chatService
          .chatControllerDeleteSession({
            sessionId: this.sessionIdToBeDeleted.toString(),
          })
          .subscribe(() => {
            this.loadMessageHistory();
            if (this.sessionID === this.sessionIdToBeDeleted) {
              this.startNewChat();
            }
          });
      }
    });
  }
}
