/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateUserDto } from '../models/create-user-dto';
import { HealthResponseDto } from '../models/health-response-dto';
import { LevelDto } from '../models/level-dto';
import { LogEntry } from '../models/log-entry';
import { MultiPurposeFindQueryParams } from '../models/multi-purpose-find-query-params';
import { RegisterUserResponseDto } from '../models/register-user-response-dto';
import { User } from '../models/user';
import { VersionResponseDto } from '../models/version-response-dto';

@Injectable({
  providedIn: 'root',
})
export class UserManagementApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation loggerControllerGetLevel
   */
  static readonly LoggerControllerGetLevelPath = '/logging/level';

  /**
   * Get logger enabled level for verbose, debug, log, error, warn
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerGetLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLevel$Response(params: {
    level: any;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.LoggerControllerGetLevelPath, 'get');
    if (params) {
      rb.query('level', params.level, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Get logger enabled level for verbose, debug, log, error, warn
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerGetLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLevel(params: {
    level: any;
  }): Observable<boolean> {

    return this.loggerControllerGetLevel$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation loggerControllerSetLevelFromRest
   */
  static readonly LoggerControllerSetLevelFromRestPath = '/logging/level';

  /**
   * Set log level to verbose, debug, log, error, warn
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerSetLevelFromRest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loggerControllerSetLevelFromRest$Response(params: {
    body: LevelDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.LoggerControllerSetLevelFromRestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Set log level to verbose, debug, log, error, warn
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerSetLevelFromRest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loggerControllerSetLevelFromRest(params: {
    body: LevelDto
  }): Observable<boolean> {

    return this.loggerControllerSetLevelFromRest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation loggerControllerGetLog
   */
  static readonly LoggerControllerGetLogPath = '/logging/log';

  /**
   * Get last log entries from FIFO
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerGetLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLog$Response(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.LoggerControllerGetLogPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageOffset', params.pageOffset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'results'?: Array<LogEntry>;
        'info'?: {
        'pageSize'?: number;
        'pageOffset'?: number;
        'recordSkip'?: number;
        'recordTotal'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Get last log entries from FIFO
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerGetLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetLog(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}> {

    return this.loggerControllerGetLog$Response(params).pipe(
      map((r: StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>) => r.body as {
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
})
    );
  }

  /**
   * Path part for operation loggerControllerGetError
   */
  static readonly LoggerControllerGetErrorPath = '/logging/error';

  /**
   * Get last error log entries from FIFO
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loggerControllerGetError()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetError$Response(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.LoggerControllerGetErrorPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageOffset', params.pageOffset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'results'?: Array<LogEntry>;
        'info'?: {
        'pageSize'?: number;
        'pageOffset'?: number;
        'recordSkip'?: number;
        'recordTotal'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Get last error log entries from FIFO
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loggerControllerGetError$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loggerControllerGetError(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;
  }): Observable<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}> {

    return this.loggerControllerGetError$Response(params).pipe(
      map((r: StrictHttpResponse<{
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>) => r.body as {
'results'?: Array<LogEntry>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
})
    );
  }

  /**
   * Path part for operation versionControllerCheck
   */
  static readonly VersionControllerCheckPath = '/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerCheck$Response(params?: {
  }): Observable<StrictHttpResponse<VersionResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.VersionControllerCheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VersionResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `versionControllerCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerCheck(params?: {
  }): Observable<VersionResponseDto> {

    return this.versionControllerCheck$Response(params).pipe(
      map((r: StrictHttpResponse<VersionResponseDto>) => r.body as VersionResponseDto)
    );
  }

  /**
   * Path part for operation healthControllerCheck
   */
  static readonly HealthControllerCheckPath = '/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthControllerCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthControllerCheck$Response(params?: {
  }): Observable<StrictHttpResponse<HealthResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.HealthControllerCheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HealthResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthControllerCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthControllerCheck(params?: {
  }): Observable<HealthResponseDto> {

    return this.healthControllerCheck$Response(params).pipe(
      map((r: StrictHttpResponse<HealthResponseDto>) => r.body as HealthResponseDto)
    );
  }

  /**
   * Path part for operation metricsControllerIndex
   */
  static readonly MetricsControllerIndexPath = '/metrics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `metricsControllerIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerIndex$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.MetricsControllerIndexPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `metricsControllerIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerIndex(params?: {
  }): Observable<void> {

    return this.metricsControllerIndex$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation metricsControllerMetricsApiKet
   */
  static readonly MetricsControllerMetricsApiKetPath = '/metrics/with-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `metricsControllerMetricsApiKet()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerMetricsApiKet$Response(params: {
    'api-key': any;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.MetricsControllerMetricsApiKetPath, 'get');
    if (params) {
      rb.query('api-key', params['api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `metricsControllerMetricsApiKet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  metricsControllerMetricsApiKet(params: {
    'api-key': any;
  }): Observable<void> {

    return this.metricsControllerMetricsApiKet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerRegisterUser
   */
  static readonly UserControllerRegisterUserPath = '/Users/register';

  /**
   * Register a new user or get existing user by unique username.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerRegisterUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerRegisterUser$Response(params: {
    body: CreateUserDto
  }): Observable<StrictHttpResponse<RegisterUserResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerRegisterUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterUserResponseDto>;
      })
    );
  }

  /**
   * Register a new user or get existing user by unique username.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerRegisterUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerRegisterUser(params: {
    body: CreateUserDto
  }): Observable<RegisterUserResponseDto> {

    return this.userControllerRegisterUser$Response(params).pipe(
      map((r: StrictHttpResponse<RegisterUserResponseDto>) => r.body as RegisterUserResponseDto)
    );
  }

  /**
   * Path part for operation userControllerGetItems
   */
  static readonly UserControllerGetItemsPath = '/Users';

  /**
   * Get many Users using GET method and URL encoded query parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGetItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetItems$Response(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;

    /**
     * MongoDB filter sort JSON string. E.g. {&quot;name&quot;:-1}
     */
    sort?: string;

    /**
     * MongoDB filter query JSON string. E.g. {&quot;name&quot;:&quot;Achim&quot;}
     */
    filter?: string;

    /**
     * MongoDB projection query JSON string. E.g. {&quot;name&quot;:&quot;$lastname&quot;}. See https://docs.mongodb.com/manual/tutorial/project-fields-from-query-results/
     */
    projection?: string;

    /**
     * Unwind array path names (comma separated) (before projection). E.g. &quot;application.names,application.parts&quot;.
     */
    unwind?: string;

    /**
     * MongoDB $group aggregation JSON string. E.g. {_id: 1, total: {$sum:&quot;$cost&quot;}
     */
    group?: string;
  }): Observable<StrictHttpResponse<{
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerGetItemsPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageOffset', params.pageOffset, {});
      rb.query('sort', params.sort, {});
      rb.query('filter', params.filter, {});
      rb.query('projection', params.projection, {});
      rb.query('unwind', params.unwind, {});
      rb.query('group', params.group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'results'?: Array<User>;
        'info'?: {
        'pageSize'?: number;
        'pageOffset'?: number;
        'recordSkip'?: number;
        'recordTotal'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Get many Users using GET method and URL encoded query parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerGetItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetItems(params?: {

    /**
     * Page size
     */
    pageSize?: number;

    /**
     * Page number
     */
    pageOffset?: number;

    /**
     * MongoDB filter sort JSON string. E.g. {&quot;name&quot;:-1}
     */
    sort?: string;

    /**
     * MongoDB filter query JSON string. E.g. {&quot;name&quot;:&quot;Achim&quot;}
     */
    filter?: string;

    /**
     * MongoDB projection query JSON string. E.g. {&quot;name&quot;:&quot;$lastname&quot;}. See https://docs.mongodb.com/manual/tutorial/project-fields-from-query-results/
     */
    projection?: string;

    /**
     * Unwind array path names (comma separated) (before projection). E.g. &quot;application.names,application.parts&quot;.
     */
    unwind?: string;

    /**
     * MongoDB $group aggregation JSON string. E.g. {_id: 1, total: {$sum:&quot;$cost&quot;}
     */
    group?: string;
  }): Observable<{
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}> {

    return this.userControllerGetItems$Response(params).pipe(
      map((r: StrictHttpResponse<{
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>) => r.body as {
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
})
    );
  }

  /**
   * Path part for operation userControllerCreateItem
   */
  static readonly UserControllerCreateItemPath = '/Users';

  /**
   * Create a User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCreateItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreateItem$Response(params: {
    body: User
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerCreateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Create a User.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerCreateItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreateItem(params: {
    body: User
  }): Observable<User> {

    return this.userControllerCreateItem$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerGetItem
   */
  static readonly UserControllerGetItemPath = '/Users/{id}';

  /**
   * Fetch a particular User using Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGetItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetItem$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerGetItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Fetch a particular User using Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerGetItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetItem(params: {
    id: string;
  }): Observable<User> {

    return this.userControllerGetItem$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerDeleteItem
   */
  static readonly UserControllerDeleteItemPath = '/Users/{id}';

  /**
   * Delete a single User  item using its Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDeleteItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDeleteItem$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerDeleteItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Delete a single User  item using its Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerDeleteItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDeleteItem(params: {
    id: string;
  }): Observable<User> {

    return this.userControllerDeleteItem$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerEditItem
   */
  static readonly UserControllerEditItemPath = '/Users/{id}';

  /**
   * Update a particular User using Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerEditItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerEditItem$Response(params: {
    id: string;
    body: User
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerEditItemPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Update a particular User using Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerEditItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerEditItem(params: {
    id: string;
    body: User
  }): Observable<User> {

    return this.userControllerEditItem$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation userControllerPostQueryItems
   */
  static readonly UserControllerPostQueryItemsPath = '/Users/query';

  /**
   * Get many Users using POST method and body encoded query parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerPostQueryItems()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPostQueryItems$Response(params: {
    body: MultiPurposeFindQueryParams
  }): Observable<StrictHttpResponse<{
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementApiService.UserControllerPostQueryItemsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'results'?: Array<User>;
        'info'?: {
        'pageSize'?: number;
        'pageOffset'?: number;
        'recordSkip'?: number;
        'recordTotal'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Get many Users using POST method and body encoded query parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerPostQueryItems$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerPostQueryItems(params: {
    body: MultiPurposeFindQueryParams
  }): Observable<{
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}> {

    return this.userControllerPostQueryItems$Response(params).pipe(
      map((r: StrictHttpResponse<{
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
}>) => r.body as {
'results'?: Array<User>;
'info'?: {
'pageSize'?: number;
'pageOffset'?: number;
'recordSkip'?: number;
'recordTotal'?: number;
};
})
    );
  }

}
