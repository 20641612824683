import { Injectable } from '@angular/core';
import {
  GroupDto,
  IFormFilters,
  MembershipTypeDto,
  OrganizationDto,
  ProductDto,
  UserChatsDto,
  UserDto,
  UserReviewDto,
} from './admin_dto';
import { ChatSession } from '@snps-webapp/angular-api-client/chat-microservice';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor() {
    //Add fake Products dAta
    for (let i = 0; i < 50; i++) {
      this.products.push({
        id: 'p-' + this.products.length + 1,
        name: this.getRandomString(20),
        version: ['2023.SP1', '2023.SP2'][Math.floor(Math.random() * 2)],
        status: Math.random() > 0.5 ? 'Active' : 'Inactive',
        groupId: '-1',
        group: 'All',
      });
    }
    //Add fake users chats data
    this.usersChatsData = this.users.map((x) => {
      const userChatData: UserChatsDto = {
        user: x,
        chatSessions: this.getChatSessions(+x.id),
        chatMessages: [],
      };
      return userChatData;
    });
    //Add fake users chats response review data
    this.userReviews = this.users.map((user, index) => {
      return {
        reviewId: index + 1,
        user: user,
        question: 'Question',
        answer: 'Answer',
        feedback: 'FeedBack',
        rating: Math.ceil(Math.random() * 5),
      };
    });
  }
  //#region Product Forms Data and Methods
  private groups: GroupDto[] = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Super Admin' },
    { id: 3, name: 'Okta Users' },
  ];
  private products: ProductDto[] = [
    {
      id: 'p-1',
      name: 'Prime Time',
      version: '2023.SP1',
      status: 'Inactive',
      groupId: '-1',
      group: 'All',
    },
    {
      id: 'p-2',
      name: 'Prime Time',
      version: '2023.SP2',
      status: 'Active',
      groupId: '-1',
      group: 'All',
    },
    {
      id: 'p-3',
      name: 'ICC2',
      version: '2023.SP1',
      status: 'Active',
      groupId: '-1',
      group: 'All',
    },
  ];
  private async getProductsList(
    filters: IFormFilters = {},
    sortField = '',
    sortOrder: 1 | -1 = 1
  ): Promise<ProductDto[]> {
    const {
      searchText = '',
      groupId = '',
      status = '',
      version = '',
    } = filters;
    const result = this.products.filter(
      (x) =>
        x.name.toLowerCase().includes(searchText.toLowerCase()) &&
        x.status === (status || x.status) &&
        x.version === (version || x.version) &&
        (x.groupId !== '-1' ? x.groupId === (groupId || x.groupId) : true)
    );
    if (sortField && result.length) {
      if (typeof result[0][sortField] !== 'undefined') {
        result.sort((a, b) =>
          a[sortField].toString().toLowerCase() >
          b[sortField].toString().toLowerCase()
            ? sortOrder
            : -sortOrder
        );
      }
    }
    return result;
  }
  public async getProducts(
    options: {
      pageIndex?: number;
      pageSize?: number;
      sortField?: string;
      sortOrder?: 1 | -1;
    } = {},
    filters: IFormFilters = {}
  ): Promise<ProductDto[]> {
    const {
      pageIndex = 0,
      pageSize = 10,
      sortField = '',
      sortOrder = 1,
    } = options;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize || Number.MAX_VALUE;
    const products = await this.getProductsList(filters, sortField, sortOrder);
    return products.filter(
      (x, index) => index >= startIndex && index < endIndex
    );
  }
  public async getTotalProducts(filters: IFormFilters = {}): Promise<number> {
    const products = await this.getProductsList(filters);
    return products.length;
  }
  public async addNewProduct(data: ProductDto): Promise<ProductDto> {
    data.id = (this.products.length + 1).toString();
    if (data.groupId == '-1') {
      data.group = 'All';
    } else {
      data.group = this.groups.find((x) => x.id === +data.groupId)?.name;
    }
    this.products.push(data);
    return data;
  }
  public async updateProduct(data: ProductDto): Promise<ProductDto> {
    if (data.groupId == '-1') {
      data.group = 'All';
    } else {
      data.group = this.groups.find((x) => x.id === +data.groupId)?.name;
    }
    this.products = this.products.map((x) => {
      if (x.id === data.id) {
        return data;
      }
      return x;
    });

    return data;
  }
  public async deleteProducts(ids: string[]): Promise<boolean> {
    let result = false;
    ids.forEach((id) => {
      const index = this.products.findIndex((x) => x.id === id);
      if (index >= 0) {
        result = true;
      }
      this.products.splice(index, 1);
    });
    return result;
  }
  public async setProductsStatus(ids: string[], status: 'Active' | 'Inactive') {
    this.products.forEach((x) => {
      if (ids.includes(x.id)) {
        x.status = status;
      }
    });
  }

  public async getGroups(): Promise<GroupDto[]> {
    return this.groups;
  }
  //#endregion

  //#region Users Forms Data and Methods
  private users: UserDto[] = [
    {
      id: '1',
      name: 'andrew_fletcher_vp',
      firstName: 'Andrew',
      lastName: 'Fletcher',
      email: 'andrew.fletcher@cyclotron.com',
      phone: '+01 8745874587',
      organizationId: '1',
      organizationName: 'Cyclotron.inc',
      status: 'Active',
    },
    {
      id: '2',
      name: 'michelle_guarrera_pm',
      firstName: 'Michelle',
      lastName: 'Guarrera',
      email: 'michelle.guarrera@cyclotron.com',
      phone: '+01 1245456523',
      organizationId: '2',
      organizationName: 'Cyclotronazure.inc',
      status: 'Active',
    },
    {
      id: '3',
      name: 'jamie_ruth_officer',
      firstName: 'Jamie',
      lastName: 'Ruth',
      email: 'jamie.ruth@cyclotron.com',
      phone: '+01 6655854125',
      organizationId: '1',
      organizationName: 'Cyclotron.inc',
      status: 'Active',
    },
    {
      id: '4',
      name: 'douglas_ware_azure',
      firstName: 'Douglas',
      lastName: 'Ware',
      email: 'douglas.ware@cyclotron.com',
      phone: '+01 3698523642',
      organizationId: '1',
      organizationName: 'Cyclotron.inc',
      status: 'Active',
    },
    {
      id: '5',
      name: 'maxim_karpov_dev',
      firstName: 'Maxim',
      lastName: 'Karpov',
      email: 'maxim.karpov@cyclotron.com',
      phone: '+01 7531597852',
      organizationId: '1',
      organizationName: 'Cyclotron.inc',
      status: 'Active',
    },
    {
      id: '6',
      name: 'seth_sanusi_azure',
      firstName: 'Seth',
      lastName: 'Sanusi',
      email: 'seth.sanusi@cyclotron.com',
      phone: '+01 4864261532',
      organizationId: '2',
      organizationName: 'Cyclotronazure.inc',
      status: 'Inactive',
    },
    {
      id: '7',
      name: 'govind_gupta_dev',
      firstName: 'Govind',
      lastName: 'Gupta',
      email: 'govind.gupta@cyclotron.com',
      phone: '+91 7841849768',
      organizationId: '1',
      organizationName: 'Cyclotron.inc',
      status: 'Inactive',
    },
  ];
  private async getUsersList(filters: IFormFilters = {}): Promise<UserDto[]> {
    const { searchText = '', organizationId = '', status = '' } = filters;

    return this.users.filter(
      (x) =>
        (x.name + x.firstName + x.lastName + x.email)
          .toLowerCase()
          .includes(searchText.toLowerCase()) &&
        x.organizationId === (organizationId || x.organizationId) &&
        x.status === (status || x.status)
    );
  }
  public async getUsers(
    options: { pageIndex?: number; pageSize?: number } = {},
    filters: IFormFilters = {}
  ): Promise<UserDto[]> {
    const { pageIndex = 0, pageSize = 10 } = options;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize || Number.MAX_VALUE;
    const users = await this.getUsersList(filters);
    return users.filter((x, index) => index >= startIndex && index < endIndex);
  }
  public async getTotalUsers(filters: IFormFilters = {}): Promise<number> {
    const users = await this.getUsersList(filters);
    return users.length;
  }
  public async getUsersSummary() {
    let active = 0;
    let inactive = 0;
    this.users.forEach((user) => {
      if (user.status === 'Active') {
        active++;
      } else {
        inactive++;
      }
    });
    return { active, inactive };
  }
  public async addNewUser(data: UserDto): Promise<UserDto> {
    data.id = (this.users.length + 1).toString();
    if (data.organizationId) {
      data.organizationName = this.organizations.find(
        (x) => x.id === data.organizationId
      )?.name;
    }

    this.users.push(data);
    return data;
  }
  public async UpdateUser(data: UserDto): Promise<UserDto> {
    if (data.organizationId) {
      data.organizationName = this.organizations.find(
        (x) => x.id === data.organizationId
      )?.name;
    }

    this.users = this.users.map((x) => {
      if (x.id === data.id) {
        return data;
      }
      return x;
    });
    return data;
  }
  public async deleteUsers(ids: string[]): Promise<boolean> {
    console.log(ids);
    let result = false;
    ids.forEach((id) => {
      const index = this.users.findIndex((x) => x.id === id);
      if (index >= 0) {
        result = true;
      }
      this.users.splice(index, 1);
    });
    return result;
  }
  public async setUsersStatus(ids: string[], status: 'Active' | 'Inactive') {
    this.users.forEach((x) => {
      if (ids.includes(x.id)) {
        x.status = status;
      }
    });
  }
  //#endregion

  //#region Organization Form Data and Methods
  private organizations: OrganizationDto[] = [
    {
      id: '1',
      name: 'Cyclotron',
      email: 'cyclotron@cyclotron.com',
      phone: '+01 8523697414',
      users: 562,
      membershipId: 1,
      membershipType: 'Lifetime',
      membershipExpire: 'Dec-31,2099',
      status: 'Active',
    },
    {
      id: '2',
      name: 'CyclotronAzure',
      email: 'cyclotronazure@cyclotron.com',
      phone: '+01 3658789413',
      users: 90,
      membershipId: 1,
      membershipType: 'Lifetime',
      membershipExpire: 'Dec-31,2099',
      status: 'Active',
    },
    {
      id: '3',
      name: 'Quantumstep',
      email: 'quantumstep@cyclotron.com',
      phone: '+01 7894561230',
      users: 8,
      membershipId: 2,
      membershipType: 'Platinum',
      membershipExpire: 'June-30,2024',
      status: 'Active',
    },
    {
      id: '4',
      name: 'Synopsys',
      email: 'synopsys@cyclotron.com',
      phone: '+01 8523697410',
      users: 84,
      membershipId: 3,
      membershipType: 'Gold',
      membershipExpire: 'Jan-31,2025',
      status: 'Active',
    },
    {
      id: '5',
      name: 'abc',
      email: 'a2z@abc.com',
      phone: '+01 7531597895',
      users: 12,
      membershipId: 4,
      membershipType: 'Silver',
      membershipExpire: 'Dec-31,2023',
      status: 'Inactive',
    },
  ];
  private memberships: MembershipTypeDto[] = [
    { id: 1, name: 'Lifetime' },
    { id: 2, name: 'Platinum' },
    { id: 3, name: 'Gold' },
    { id: 4, name: 'Silver' },
  ];
  private async getOrganizationsList(
    filters: IFormFilters = {}
  ): Promise<OrganizationDto[]> {
    const { searchText = '', membershipIds = [], status = '' } = filters;
    return this.organizations.filter(
      (x) =>
        (x.name + x.email).toLowerCase().includes(searchText.toLowerCase()) &&
        x.status === (status || x.status) &&
        (membershipIds.length && !membershipIds.includes(-1)
          ? membershipIds.includes(x.membershipId)
          : true)
    );
  }
  public async getOrganizations(
    options: {
      status?: 'Active' | 'Inactive';
      pageIndex?: number;
      pageSize?: number;
    } = {},
    filters: IFormFilters = {}
  ): Promise<OrganizationDto[]> {
    const { pageIndex = 0, pageSize = 10, status } = options;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize || Number.MAX_VALUE;
    const organizations = await this.getOrganizationsList(filters);
    return organizations.filter(
      (x, index) =>
        x.status === (status || x.status) &&
        index >= startIndex &&
        index < endIndex
    );
  }
  public async getTotalOrganizations(
    filters: IFormFilters = {}
  ): Promise<number> {
    const organizations = await this.getOrganizationsList(filters);
    return organizations.length;
  }
  public async getOrganizationSummary() {
    return this.organizations
      .filter((x) => x.status === 'Active')
      .map((x) => {
        const { id, name, users } = x;
        return { id, name, users };
      });
  }
  public async getMembershipTypes(): Promise<MembershipTypeDto[]> {
    return this.memberships;
  }
  public async addNewOrganization(
    data: OrganizationDto
  ): Promise<OrganizationDto> {
    data.id = (this.organizations.length + 1).toString();
    if (data.membershipId) {
      data.membershipType = this.memberships.find(
        (x) => x.id === data.membershipId
      )?.name;
    }
    if (typeof data.membershipExpire === 'object') {
      data.membershipExpire = this.dateToString(data.membershipExpire);
    }
    this.organizations.push(data);
    return data;
  }
  public async UpdateOrganization(
    data: OrganizationDto
  ): Promise<OrganizationDto> {
    if (data.membershipId) {
      data.membershipType = this.memberships.find(
        (x) => x.id === data.membershipId
      )?.name;
    }
    if (typeof data.membershipExpire === 'object') {
      data.membershipExpire = this.dateToString(data.membershipExpire);
    }
    this.organizations = this.organizations.map((x) => {
      if (x.id === data.id) {
        return data;
      }
      return x;
    });
    return data;
  }
  public async deleteOrganizations(ids: string[]): Promise<boolean> {
    let result = false;
    ids.forEach((id) => {
      const index = this.organizations.findIndex((x) => x.id === id);
      if (index >= 0) {
        result = true;
      }
      this.organizations.splice(index, 1);
    });
    return result;
  }
  public async setOrganizationsStatus(
    ids: string[],
    status: 'Active' | 'Inactive'
  ) {
    this.organizations.forEach((x) => {
      if (ids.includes(x.id)) {
        x.status = status;
      }
    });
  }
  dateToString(val) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const d = new Date(val);
    return `${months[d.getMonth()]}-${d.getDate()},${d.getFullYear()}`;
  }
  //#endregion

  //#region  Users Chats Data and Methods
  private usersChatsData: UserChatsDto[] = [];
  private async getUsersChatsList(
    filters: IFormFilters = {}
  ): Promise<UserChatsDto[]> {
    const { searchText = '', organizationId = '', status = '' } = filters;

    return this.usersChatsData.filter(
      (x) =>
        (x.user.name + x.user.firstName + x.user.lastName + x.user.email)
          .toLowerCase()
          .includes(searchText.toLowerCase()) &&
        x.user.organizationId === (organizationId || x.user.organizationId) &&
        x.user.status === (status || x.user.status)
    );
  }
  public async getUsersChat(
    options: { pageIndex?: number; pageSize?: number } = {},
    filters: IFormFilters = {}
  ): Promise<UserChatsDto[]> {
    const { pageIndex = 0, pageSize = 10 } = options;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize || Number.MAX_VALUE;
    const usersChatsList = await this.getUsersChatsList(filters);
    return usersChatsList.filter(
      (x, index) => index >= startIndex && index < endIndex
    );
  }
  public async getTotalUsersChats(filters: IFormFilters = {}): Promise<number> {
    const usersChatsList = await this.getUsersChatsList(filters);
    return usersChatsList.length;
  }
  getChatSessions(login_id: number): ChatSession[] {
    const chatSessions: ChatSession[] = [];
    for (let i = 0, j = Math.floor(Math.random() * 10 + 1); i < j; i++) {
      chatSessions[i] = {
        login_id: login_id,
        session_id: i,
        session_title: 'Chat-Session-' + i,
      };
    }
    return chatSessions;
  }
  //#endregion

  //#region  Users Response Review Forms Data and Methods
  private userReviews: UserReviewDto[] = [];
  public async getUserReviewList(
    filters: IFormFilters = {}
  ): Promise<UserReviewDto[]> {
    const { searchText = '', organizationId = '', rating = 0 } = filters;
    return this.userReviews.filter(
      (x) =>
        (x.user.name + x.user.firstName + x.user.lastName + x.user.email)
          .toLowerCase()
          .includes(searchText.toLowerCase()) &&
        x.user.organizationId === (organizationId || x.user.organizationId) &&
        x.rating === (rating || x.rating)
    );
  }
  public async getUserReview(
    options: {
      userId?: string;
      rating?: number;
      pageIndex?: number;
      pageSize?: number;
    } = {},
    filters: IFormFilters = {}
  ): Promise<UserReviewDto[]> {
    const { pageIndex = 0, pageSize = 10, userId, rating } = options;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize || Number.MAX_VALUE;
    const usrsReviewsList = await this.getUserReviewList(filters);
    return usrsReviewsList.filter(
      (x, index) =>
        x.user.id === (userId || x.user.id) &&
        x.rating === (rating || x.rating) &&
        index >= startIndex &&
        index < endIndex
    );
  }
  public async getTotalReviews(filters: IFormFilters = {}): Promise<number> {
    const usrsReviewsList = await this.getUserReviewList(filters);
    return usrsReviewsList.length;
  }
  getRandomString(maxLength: number, includes: string[] = []): string {
    let str = '';
    const chars: string[] = includes;
    for (let i = 0; i < 26; i++) {
      chars.push(String.fromCharCode(97 + i));
    }
    for (let i = 0, j = Math.random() * maxLength; i < j; i++) {
      str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
  }
  //#endregion
}
