import { Component, OnDestroy, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { AdminService } from '../../../services/admin/admin.service';
import { UserDto } from '../../../services/admin/admin_dto';

@Component({
  selector: 'fse-data-doughnut-chart',
  templateUrl: './data-doughnut-chart.component.html',
  styleUrls: ['./data-doughnut-chart.component.scss'],
})
export class DataDoughnutChartComponent implements OnInit, OnDestroy {
  constructor(public adminService: AdminService) {}
  async ngOnInit() {
    this.usersSummary = await this.adminService.getUsersSummary();
    this.showChart();
  }
  ngOnDestroy() {
    this.chart.destroy();
  }
  usersSummary = {};
  title = 'ng-chart';
  chart: any = [];
  showChart() {
    this.chart = new Chart('data-doughnut-chart', {
      type: 'doughnut',
      data: {
        labels: Object.keys(this.usersSummary),
        datasets: [
          {
            label: ' users ',
            data: Object.values(this.usersSummary),
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Users Summary',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        aspectRatio: 1.2,
        responsive: true,
      },
    });
  }
}
