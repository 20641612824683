import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFormFilters,
  OrganizationDto,
  UserReviewDto,
} from '../../services/admin/admin_dto';
import { AdminService } from '../../services/admin/admin.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'fse-response-review',
  templateUrl: './response-review.component.html',
  styleUrls: ['./response-review.component.scss'],
})
export class ResponseReviewComponent implements OnInit {
  constructor(public dialog: MatDialog, public adminService: AdminService) {}

  async ngOnInit() {
    this.organizations = await this.adminService.getOrganizations({
      status: 'Active',
    });
    await this.updateUsersReview();
  }
  organizations: OrganizationDto[] = [];
  userReview: UserReviewDto[] = [];
  totalRecords = 0;
  pageSize = 10;
  pageIndex = 0;
  filters: IFormFilters = {};
  checkedItems: number[] = [];

  checkUncheck(id: number) {
    if (this.checkedItems.includes(id)) {
      this.checkedItems = this.checkedItems.filter((x) => x !== id);
    } else {
      this.checkedItems.push(id);
    }
  }
  checkAll() {
    if (this.checkedItems.length === this.userReview.length) {
      this.checkedItems = [];
    } else {
      this.checkedItems = this.userReview.map((x) => x.reviewId);
    }
  }
  isChecked(id: number) {
    return this.checkedItems.includes(id);
  }
  isAllChecked() {
    return (
      this.userReview.length > 0 &&
      this.checkedItems.length === this.userReview.length
    );
  }
  isSomeChecked(): boolean {
    return (
      this.checkedItems.length > 0 &&
      this.checkedItems.length !== this.userReview.length
    );
  }
  async handlePageEvent(event: PageEvent) {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.updateUsersReview();
  }
  async updateUsersReview() {
    this.userReview = await this.adminService.getUserReview(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      },
      this.filters
    );
    this.totalRecords = await this.adminService.getTotalReviews(this.filters);
  }
  async filterRecords(data: IFormFilters) {
    const { searchText, organizationId, rating } = data;
    this.pageIndex = 0;
    this.filters = { searchText, organizationId, rating };
    console.log('Filter:', this.filters);
    this.updateUsersReview();
  }
}
