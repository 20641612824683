import { Component, OnDestroy, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { AdminService } from '../../../services/admin/admin.service';

@Component({
  selector: 'fse-data-bar-chart',
  templateUrl: './data-bar-chart.component.html',
  styleUrls: ['./data-bar-chart.component.scss'],
})
export class DataBarChartComponent implements OnInit, OnDestroy {
  constructor(public adminService: AdminService) {}
  orgSummary = [];
  title = 'ng-chart';
  chart: any = undefined;
  async ngOnInit() {
    this.orgSummary = await this.adminService.getOrganizationSummary();
    console.log(this.orgSummary);
    console.log(this.orgSummary.map((x) => x.name));
    console.log(this.orgSummary.map((x) => x.users));
    this.initGraph();
    this.chart.update();
  }
  initGraph() {
    const ctx = (
      document.querySelector('#data-bar-chart') as HTMLCanvasElement
    ).getContext('2d');

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.orgSummary.map((x) => x.name),
        datasets: [
          {
            label: ' Users ',
            data: this.orgSummary.map((x) => x.users),
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Active Organization Users Count',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        aspectRatio: 2.4,
        responsive: true,
      },
    });
  }
  ngOnDestroy() {
    this.chart && this.chart.destroy();
  }
}
