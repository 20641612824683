import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { DiagnosticDashboardComponent } from './diagnostic-dashboard/diagnostic-dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ChatComponent } from './chat/chat/chat.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { ProductsComponent } from './admin/products/products.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { UsersComponent } from './admin/users/users.component';
import { ChatsComponent } from './admin/chats/chats.component';
import { ResponseReviewComponent } from './admin/response-review/response-review.component';

const routes: Routes = [
  { path: 'logout', component: LogoutComponent },
  { path: 'diagnostic', component: DiagnosticDashboardComponent },
  { path: 'user-management', component: UserManagementComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'chat/:sessionId', component: ChatComponent },
  // { path: 'admin/dashboard', component: DashboardComponent },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'organizations', component: OrganizationsComponent },
      { path: 'users', component: UsersComponent },
      { path: 'chats', component: ChatsComponent },
      { path: 'response-review', component: ResponseReviewComponent },
    ],
  },
  {
    path: '**',
    redirectTo: 'chat',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
