<header class="flex">
  <div class="header-sidenav">
    <mat-icon class="header-sidenav-icon" (click)="toggleSideBar()">
      menu
    </mat-icon>
  </div>


  <div class="header_logo pointer">

    <img src="assets/images/logo-bg.png" alt="" class="header_logo-bg" />
    <img src="assets/images/synopsys-txt.png" alt="" class="header_logo-text" />
  </div>

  <div class="flex flex-grow sm:hidden sm:w-0 sm:overflow-hidden">
    <div class="header_nav-icons">
      <div class="align-center disp-flex solvenet-block">
        <div class="solvenet_nav pointer">Knowledge Assistant (bot) for Synopsys products</div>
      </div>
    </div>
  </div>

  <div class="flex justify-center items-center text-white ">
    <div class="text-right text-xs">
      <div>{{userProfile && userProfile.firstName}} {{userProfile && userProfile.lastName}}</div>
      <div>{{userProfile && (userProfile.email||userProfile.username)}}</div>
    </div>
    <span class="material-icons md-48 cursor-pointer" style="font-size: 48px;"
      [matMenuTriggerFor]="profileMenu">account_circle</span>

    <mat-menu #profileMenu="matMenu" xPosition="after">
      <div *ngFor="let option of profileOptions">
        <a mat-menu-item [routerLink]="[option.routerLink]">
          {{ option.name }}
        </a>
      </div>
    </mat-menu>
  </div>




</header>