<form #organizationDetail="ngForm" class="flex flex-col w-full h-full pb-4"
  (ngSubmit)="onSubmitForm(organizationDetail.value)">
  <h1 mat-dialog-title>Add New User</h1>
  <div mat-dialog-content class="flex-grow flex-shrink !max-h-[unset] overflow-visible">
    <h1>User Detail</h1>

    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input type="text" ngModel name="name" required="*" matInput placeholder="Enter User Name Here" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>First Name</mat-label>
      <input type="text" ngModel name="firstName" required="*" matInput placeholder="Enter First Name" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Last Name</mat-label>
      <input type="text" ngModel name="lastName" required="*" matInput placeholder="Enter Last Name" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Email-ID</mat-label>
      <input type="email" ngModel name="email" required="*" matInput placeholder="ex: abc@example.com" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Phone</mat-label>
      <input type="phone" ngModel name="phone" required="*" matInput placeholder="+01 124567890" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization</mat-label>
      <mat-select ngModel required="*" name="organizationId">
        <mat-option *ngFor="let organization of organizations" class="MembershipOther"
          [value]="organization.id">{{organization.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>User Status</mat-label>
      <mat-select required="*" name="status" [ngModel]="default.value">
        <mat-option value="Active" #default selected>Active</mat-option>
        <mat-option value="Inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div mat-dialog-actions align="center">
    <button mat-button class="primary-button-bg" [disabled]="!organizationDetail.valid">Save</button>
    <button mat-button cdkFocusInitial mat-dialog-close>Cancel</button>
  </div>
</form>