import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
  AdminService,
  ProductDto,
  ProductGroup,
} from '@snps-webapp/angular-api-client/chat-microservice';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'fse-add-new-product',
  templateUrl: './add-new-product.component.html',
  styleUrls: ['./add-new-product.component.scss'],
})
export class AddNewProductComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddNewProductComponent>,
    public adminService: AdminService
  ) {}
  async ngOnInit() {
    this.groups = await lastValueFrom(
      this.adminService.adminDataControllerGetProductGroups()
    );
  }
  groups: ProductGroup[] = [];

  onSubmitForm(formData: ProductDto) {
    console.log(formData);
    lastValueFrom(
      this.adminService.adminDataControllerAddProduct({ body: formData })
    )
      .then((data) => {
        console.log('onSubmitForm:', data);
        this.dialogRef.close(data);
      })
      .catch((err) => {
        console.error('onSubmitForm:', err);
      });
  }
}
