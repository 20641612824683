<div class="flex flex-col px-4 py-4 w-full h-full relative">
  <div>
    <h1 class="text-3xl">Product Management</h1>
  </div>
  <div>
    <form class="flex items-center  sm:flex-wrap sm:[&>*]:w-full " #formFilters="ngForm"
      (ngSubmit)="filterRecords(formFilters.value)">
      <mat-form-field class="">
        <mat-label>Search Product</mat-label>
        <input type="search" ngModel name="searchText" matInput placeholder="Ex. Synopsys" value="">
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Version</mat-label>
        <input type="text" ngModel name="version" matInput placeholder="1.0" value="">
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Status</mat-label>
        <mat-select ngModel name="status">
          <mat-option>-- ALL --</mat-option>
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Inactive">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>By Group</mat-label>
        <mat-select ngModel name="groupId">
          <mat-option>-- ALL --</mat-option>
          <mat-option *ngFor="let group of groups" [value]="group.group_id">{{group.group}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button color="primary" class="primary-button-bg mx-2">
        <mat-icon>search</mat-icon>
        Search
      </button>
    </form>
  </div>
  <div class="w-full my-2 overflow-y-visible sm:flex-grow overflow-x-auto sm:overflow-y-hidden sm:mt-12">
    <table class="table-auto w-full text-left my-2 min-w-[800px]">
      <thead>
        <tr class=" border-b-2 border-b-slate-200">
          <th class="w-[50px]">
            <mat-checkbox [indeterminate]="isSomeChecked()" [checked]="isAllChecked()"
              (change)="checkAll()"></mat-checkbox>
          </th>
          <th>
            <div class="flex justify-between items-center">
              <span class="cursor-pointer flex items-center" (click)="sortProductsList('name',sortOrder)">Product Name
                <mat-icon *ngIf="sortField==='name'">{{sortOrder===1?'arrow_upward':'arrow_downward'}}</mat-icon>
              </span>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="deleteSelected()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Selected</span>
                  </button>
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="updateProductStatus('Active')">
                    <mat-icon>done_all</mat-icon>
                    <span>Activate Selected</span>
                  </button>
                  <button mat-menu-item [disabled]="checkedItems.length===0" (click)="updateProductStatus('Inactive')">
                    <mat-icon>remove_done</mat-icon>
                    <span>Inactive Selected</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </th>
          <th>
            <span class="cursor-pointer flex items-center" (click)="sortProductsList('version',sortOrder)">Version
              <mat-icon *ngIf="sortField==='version'">{{sortOrder===1?'arrow_upward':'arrow_downward'}}</mat-icon>
            </span>
          </th>
          <th>
            <span class="cursor-pointer flex items-center" (click)="sortProductsList('status',sortOrder)">Status
              <mat-icon *ngIf="sortField==='status'">{{sortOrder===1?'arrow_upward':'arrow_downward'}}</mat-icon>
            </span>
          </th>
          <th>
            <span class="cursor-pointer flex items-center" (click)="sortProductsList('group',sortOrder)">Group
              <mat-icon *ngIf="sortField==='group'">{{sortOrder===1?'arrow_upward':'arrow_downward'}}</mat-icon>
            </span>
          </th>
          <th class="w-[300px]">
            <div class="flex justify-between items-center ">
              <span>Action</span>
              <button mat-button matTooltip="Add Product" class="sm:!absolute sm:right-2 sm:mb-20"
                (click)="addNewProductClick()"><mat-icon>add</mat-icon>Add New
                Product</button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of products" class="hover:bg-slate-200">
          <td>
            <mat-checkbox [checked]="isChecked(product.productId)"
              (change)="checkUncheck(product.productId)"></mat-checkbox>
          </td>
          <td>
            {{product.name}}
          </td>
          <td>
            {{product.version}}
          </td>
          <td>
            {{product.status}}
          </td>
          <td>
            {{product.group}}
          </td>
          <td>
            <button mat-button matTooltip="Edit Product"
              (click)="editProductClick(product.productId)"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button matTooltip="Delete Product"
              (click)="deleteProduct(product.productId)"><mat-icon>delete</mat-icon>Delete</button>
            <button mat-button matTooltip="Activate Product" (click)="updateProductStatus('Active',product.productId)"
              *ngIf="product.status!=='Active'"><mat-icon>done_all</mat-icon>Activate</button>
            <button mat-button matTooltip="Deactivate Product"
              (click)="updateProductStatus('Inactive',product.productId)"
              *ngIf="product.status==='Active'"><mat-icon>remove_done</mat-icon>Deactivate</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="flex flex-col flex-grow flex-shrink">
    <mat-divider></mat-divider>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"
      style="background-color:unset;">
    </mat-paginator>
  </div>
</div>