<mat-card class="flex flex-col justify-between w-full min-h-[300px] px-8 py-8">
  <div class="flex justify-between items-center text-xl">
    <span>Total Users</span>
    <span>189,800</span>
  </div>
  <div class="flex justify-between items-center text-xl">
    <span>Total Chat Conversation</span>
    <span>1,258,000</span>
  </div>
  <div class="flex justify-between items-center text-xl">
    <span>Avg Response Time</span>
    <span>36.145ms</span>
  </div>
</mat-card>