<form #organizationDetail="ngForm" class="flex flex-col w-full h-full pb-4"
  (ngSubmit)="onSubmitForm(organizationDetail.value)">
  <h1 mat-dialog-title>Add New Organization</h1>
  <div mat-dialog-content class="flex-grow flex-shrink !max-h-[unset] overflow-visible">
    <h1>Organization Detail</h1>

    <mat-form-field class="w-full">
      <mat-label>Organization Name</mat-label>
      <input type="text" ngModel name="name" required="*" matInput placeholder="Enter Organization Name Here" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Email-ID</mat-label>
      <input type="email" ngModel name="email" required="*" matInput placeholder="Ex. company@gmail.com" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Phone</mat-label>
      <input type="text" ngModel name="phone" required="*" matInput placeholder="+01 1234567890" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Users Count</mat-label>
      <input type="number" ngModel name="users" required="*" matInput placeholder="Enter Users Count" value="">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Membership Type</mat-label>
      <mat-select ngModel required="*" name="membershipId">
        <mat-option *ngFor="let membership of memberships" class="MembershipOther"
          [value]="membership.id">{{membership.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Membership Expire on</mat-label>
      <input readonly ngModel name="membershipExpire" required="*" matInput [matDatepicker]="picker"
        placeholder="Expiry Date">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Organization Status</mat-label>
      <mat-select required="*" name="status" [ngModel]="default.value">
        <mat-option value="Active" #default selected>Active</mat-option>
        <mat-option value="Inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div mat-dialog-actions align="center">
    <button mat-button class="primary-button-bg" [disabled]="!organizationDetail.valid">Save</button>
    <button mat-button cdkFocusInitial mat-dialog-close>Cancel</button>
  </div>
</form>